<template>
  <div class="panel">
    <panel-header />
    <div class="panel__content">
      <div class="panel__content-left">
        <p class="panel__content-left__title">{{ $t('contacts.main_title_header') }}</p>
        <p class="panel__content-left__text">{{ $t('contacts.main_title_content') }}</p>

         <div class="panel__content-left-icon" >
            <div class="panel__content-left-icon__item telegram" @click="redirectToTg('SHS')"></div>
            <div class="panel__content-left-icon__item viber" @click="redirectToViber"></div>
            <div class="panel__content-left-icon__item mail" @click="redirectToEmail"></div>
            <div class="panel__content-left-icon__item watsapp" @click="redirectToWatsapp"></div>
         </div>

      </div>


      <div class="panel__content-right">
        <img src="@/assets/images/guests.png" alt="img" class="panel__content-right-img" id="guests" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";

const { redirectToTg, redirectToViber, redirectToWatsapp, redirectToEmail } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";

.panel {
  &__content {
    &-left {
      @extend .flex-col;
      &-icon {
        @extend .flex-row, .pointer;
        &__item {

          &.viber {
            @include svgBg($viber);
          }
          &.telegram {
            @include svgBg($telegram-black);
          }
          &.mail {
            @include svgBg($mail);
          }
          &.watsapp {
            @include svgBg($watsapp);
          }
        }
      }
    }
  }
}

.desktop .panel {
  margin-bottom: 7.27vw;

  &__content {
    @extend .flex-row;
    justify-content: space-between;

    &-left {
      gap: 3.04vw;
      width: 32.5vw;
      margin-top: 7.2vw;
      margin-left: 2.38vw;

      &-icon{
        &__item {
          width: 4.6296vw;
          min-width: 4.6296vw;
          height: 3.7037vw;
          margin: 0 1.2vw 1.4vw 0;
        }
      }

      &__title {
        @include font(4vw, 120%, $text, 600);
      }

      &__text {
        @include font-24();
      }
    }

    &-right {
      @extend .row-container;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.58vw;
      width: calc(23.8vw * 2 + 1.58vw);
      margin: calc(min(3.8vw, 5vh)) 0.75vw 0.75vw 0;

      &-img#guests{
        width: 36.1772vw;
        height: 22.8175vw;
      }

    }
  }
}

.mobile .panel {
  margin-bottom: 12.3vw;

  &__content {
    @extend .flex-col;
    margin-top: 18vw;

    &-left {
      margin-bottom: 6.15vw;

      &__title {
        margin-bottom: 4.1vw;
        @include font(9.23vw, 120%, $text, 600);
      }

      &__text {
        @include font-18();
      }

      &-icon {
        @include square(12.8vw);
        @extend .flex-col;
        flex-wrap: wrap;
        &__item {
          width: 20vw;
          min-width: 20vw;
          height: 20vw;
          margin: 2vw 0.5vw 0.4vw 0;
        }
      }
    }

    &-right {
      @extend .flex-col;
      gap: 4.1vw;
      margin-bottom: 17vw;
    }

    &-contact {
      gap: 2.05vw;
      flex: 1;
      min-height: 38vw;
      border-radius: 30px;

      &__title {
        @include font-20($weight: 600);
      }

      &__text {
        @include font-18($align: center);
      }

      &__icon {
        @include square(12.8vw);
      }
    }
  }
}
</style>
