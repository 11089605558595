<script setup>
import { ref, computed, useSlots } from "vue";
import { vSlide } from '@/directives/slide';
const slots = useSlots(); // Отримуємо слоти

const currentIndex = ref(0);

// ✅ Безпечний підрахунок слайдів
const slides = computed(() => slots.default ? slots.default() : []);
const slideCount = computed(() => slides.value.length);

// ✅ Обчислюємо стиль для зсуву слайдів
const sliderStyle = computed(() => ({
  transform: `translateX(-${currentIndex.value * 100}%)`,
}));

// ✅ Функція для перемикання слайдів
const goToSlide = (index) => {
  currentIndex.value = index;
};

const onSlide = (direction) => {
  console.log('direction', direction);
  console.log('currentIndex.value', currentIndex.value);
  if (direction === 'right') {
    // console.log('direction', direction);
    if(currentIndex.value < (slideCount.value-1)) goToSlide(currentIndex.value + 1)
  } else {
    // showPrevious();
    if(currentIndex.value > 0) goToSlide(currentIndex.value - 1)
  }
};
</script>

<template>
  <div class="slider"  v-slide="onSlide">
    <div class="slides-envelope" :style="sliderStyle">
      <!-- ✅ Додаємо обгортку для кожного переданого слайда -->
      <div class="slide" v-for="(slide, index) in slides" :key="index">
        <component :is="slide" />
      </div>
    </div>

    <!-- ✅ Пагінація -->
    <div class="pagination" v-if="slideCount > 1">
      <button
          v-for="(_, index) in slideCount"
          :key="index"
          :class="{ active: index === currentIndex }"
          @click="goToSlide(index)"
      >
<!--        {{ index + 1 }}-->
        &nbsp;
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss" >

$inner-height: inherit;
$slider-height: calc($inner-height + 30px);


.slider {
  width: inherit;
  height: $slider-height;
  overflow: hidden;
/*  overflow-y: visible;*/
  position: relative;
}

/* Контейнер зі слайдами */
.slides-envelope {
  width: inherit;
  height: $inner-height;
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Окремий слайд */
.slide {
  width: 100%;
  height: $inner-height;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  flex-shrink: 0;
}

/* Пагінація */
.pagination {
  gap: 5px;
  margin-top: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  button {
    width: 10.2564vw; /* Довжина смужки */
    height: 3px; /* Товщина смужки */
    box-sizing: border-box;
    background: $slider-grey; /* Неактивний колір */
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    &.active {
      background: $primary; /* Активний колір */
    }
  }
}

</style>
