<template>
  <div class="section">
    <p class="title">{{ $t('home.offer_title') }}</p>
    <p class="section__text">{{ $t('home.offer_text') }}</p>
    <base-button class="base-button" :text="$t('home.offer_button')" @click="redirectToTg('improve_business')" />
  </div>
</template>

<script setup lang="ts">
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";

const { redirectToTg } = useButton();
</script>

<style scoped lang="scss">
.section {
  @extend .col-container;
}

.desktop .section {
  margin: 5vw 0 6vw;

  .title {
    margin-bottom: 1.85vw;
  }

  .base-button {
    width: 43.58vw;
  }

  &__text {
    width: 43.58vw;
    @include font-24($align: center);
    margin-bottom: 2.38vw;
  }
}

.mobile .section {
  margin: 8vw 0 9vw;

  .title {
    margin-bottom: 4.1vw;
    text-align: left;
    @include font-28($align: left, $height: 120%);
  }

  .base-button {
    text-transform: uppercase;
  }

  &__text {
    @include font-18($align: left, $height: 120%, $color: $grey);
    margin-bottom: 8.72vw;
  }
  .base-button{
    text-transform: none;
  }
}
</style>
