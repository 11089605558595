<template>
  <div class="panel-wrapper">
    <language-selector v-if="!isMenuVisible" class="language-selector-mobile"  />
    <div class="panel__header">
      <project-logo class="panel__header-menu" @click="toggleMenu"/>
      <div class="panel__header-tabs">
        <div class="panel__logo" style="">
          <project-logo @click="router.push('/')"/>
        </div>
        <p v-for="tab in tabs" :key="tab" class="panel__header-tabs__item"
           :class="{'panel__header-tabs__item--selected': tab === selectedTab}" @click="push(`/${tab}`) ">
          {{ $t(`tabs.${ tab }`) }}
        </p>
        <!--      <p  class="panel__header-tabs__item contacts"
                 :class="{'panel__header-tabs__item&#45;&#45;selected': tab === selectedTab}" @click="push('contacts')">
                {{ $t(`tabs.contacts`) }}
              </p>-->
        <language-selector />
      </div>
<!--      <div class="panel__header-menu" @click="toggleMenu"></div>-->
      <menu-popup v-if="isMenuVisible" :tabs="tabs_popup" :selected-tab="selectedTab" @close="toggleMenu"/>

    </div>
  </div>
</template>

<script setup lang="ts">
import ProjectLogo from "@/components/ProjectLogo.vue";
import { computed, ref } from "vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import { useRoute, useRouter } from "vue-router";
import MenuPopup from "@/components/Popup/MenuPopup.vue";
import router from "@/router";

const { push } = useRouter();
const route = useRoute();

const tabs_popup = ref(['about', 'contacts']);
const tabs = ref([/*'about', 'client', 'hotel', 'prices',*/ 'contacts']);
const isMenuVisible = ref(false);

const toggleMenu = () => {
  isMenuVisible.value = !isMenuVisible.value;
};

const selectedTab = computed((): string => {
  const path = route.path.slice(1);
  if (path === 'privacy' || path === 'conditions') {
    return 'hotel';
  } else {
    return path;
  }
});
</script>

<style scoped lang="scss">
.panel-wrapper{
  @extend .flex-col;
  justify-content: space-between;
  vertical-align: top;
}
.panel__logo{
  /*flex: 3;
  display: flex;
  align-content: center;
  align-items: center;*/
  display: inline-flex; flex:2;
}
.panel__header {
  @extend .row-container;
  position: relative;
 @extend .rounded;

  &-tabs {
    @extend .row-container, .rounded;
    gap: 0.8vw;
    height: 5.03vw;
    margin: 0 0 1.72vw 0;
    padding: 0.7vw 1.3vw;
    background-color: #f1f1f1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      @extend .row-container, .pointer, .rounded;
      height: calc(100% - 0.8vw * 2);
      padding: 0.66vw;
      @include font-20();
      display: flex;

     &:last-child {
       margin-left: auto; /* Зміщує останній елемент вправо */
       justify-content: flex-end; /* Вирівнює вміст останнього елемента вправо */
       flex-grow: 2;
       font-weight: bolder;
      }

      &--selected {
        /*background-color: $text;
        color: white;*/
        font-weight: bolder;
        &::before {
          content: '';
          left: 0;
          top: 50%;
          width: 0.5vw;
          height: 0.5vw;
          background-color: $primary;
          border-radius: 20%;
          border-width: 0;
          margin-right: 0.5vw;
        }
      }
    }
  }

  &-menu {
    @extend .pointer;
    /*@include icon($menu, 16.15vw);*/
    height: 12.3077vw;
    width: 26.2564vw;
    position: relative;
    left: -2vw;
    svg{
      /*height: 16.15vw;
      width: 30vw;*/
/*      left: -2vw;
      position: relative;*/
    }
  }
}

.desktop {
  .panel__header-menu {
    display: none;
  }
  .language-selector-mobile{
    display: none;
  }

}

.mobile {
  .language-selector-mobile {
    /*display: none;*/
    /*position: absolute;*/
    right: 0;
    top: calc(-20.3vw + 4.1vw);
    z-index: 10000;
  }

  .panel__header {
    position: absolute;
    top: calc(-22.3vw + 4.1vw);
    left: 0;
    justify-content: space-between;
    background: $light-grey;
    height: 18.2051vw;

    width: 100%;
    margin-bottom: 0.5rem;


    &-tabs {
      display: none;
    }
  }
}
</style>
