export const payments = {
    title: 'ГНУЧКІ ВАРІАНТИ ОПЛАТИ',
    section: [
        {
            title1: 'Оплата “за фактом:',
            title2: 'використання”:',
            subtitle: 'Який функціонал ви отримуєте?',
            items: [
                {
                    title: 'Особистий кабінет гостя:',
                    text: 'Гість отримує доступ до персоналізованої інформації про бронювання та послуги, доступ через QR Code.'
                },
                {
                    title: 'Замовлення послуг:',
                    text: 'Можливість замовити прибирання, їжу та напої, викликати таксі чи надіслати запит на ресепшн із вибором часу й дат.'
                },
                {
                    title: 'Резервація додаткових послуг:',
                    text: 'Можливість замовити спа,забронювати сауну чи будь яку додаткову послугу готелю.'
                },
                {
                    title: 'Реклама послуг і партнерів:',
                    text: 'Банери на головному екрані для просування ваших послуг або продуктів партнерів.'
                },
                {
                    title: 'Відгуки гостей:',
                    text: 'Можливість залишити відгук після кожної послуги, збереження інформації для аналізу та зв\'язку з клієнтом.'
                },
                {
                    title: 'Робочий кабінет співробітників:',
                    text: 'Усі запити гостей надходять у персоналізований кабінет із пуш-сповіщеннями для контролю.'
                },
                {
                    title: 'Аналітика та контроль:',
                    text: 'Моніторинг виконання запитів, аналіз швидкості роботи персоналу.'
                },
                {
                    title: 'База  даних гостей:',
                    text: 'Відсліковуйте кіл-ть гостей на місяць/тиждень/день. Зберігайте іх дані та преференціі для забезпечення найкращого сервісу.'
                },
            ],
        },
        {
            subtitle: 'Додаткові переваги:',
            subtext: 'Ми не лише наповнюємо додаток інформацією про ваш готель, але й забезпечуємо повну підтримку:',
            subtext2: 'З нами ваш персонал готовий до роботи, а всі питання вирішуються оперативно!',
            items: [
                {
                    title: 'Навчання персоналу:',
                    text: 'Проводимо інструктаж для працівників, щоб вони впевнено користувалися додатком.'
                },
                {
                    title: 'Налаштування:',
                    text: 'Допомагаємо з налаштуванням та створенням акаунтів для команди.'
                },
                {
                    title: 'Щоденна підтримка:',
                    text: 'Завжди на зв’язку, швидко вирішуємо питання та забезпечуємо консультації, незалежно від обраного пакета.'
                },
            ],
        },
        {
            subtitle: 'Вартість',
            subtext: 'Вам не потрібно платити, якщо ваші гості не використовують застосунок. Ви сплачуєте лише за фактичне використання',
            items: [
                {
                    title: 'До 100 клієнтів:',
                    text: 'Тобто : якщо у готелі  наприклад 80 клієнтів за місяць скористались додатком  - рахуємо по тарифу 40грн за клієнта.',
                    price: '40 ГРН / 1 клієнт'
                },
                {
                    title: 'Від 100 до 250 клієнтів:',
                    text: 'Тобто : якщо у готелі наприклад 170 клієнтів за місяць скористались додатком  - рахуємо по тарифу 30грн за клієнта',
                    price: '30 ГРН / 1 клієнт'
                },
                {
                    title: 'Понад 250 клієнтів:',
                    text: 'Тобто : якщо у готелі наприклад 270 клієнтів за місяць скористались додатком  - рахуємо по тарифу 20грн за клієнта.',
                    price: '20 ГРН / 1 клієнт'
                },
                {
                    title: 'Понад 500 клієнтів:',
                    text: 'Тобто : якщо у готелі наприклад 550 клієнтів за місяць скористались додатком  - рахуємо по тарифу 10грн за клієнта.',
                    price: '10 ГРН / 1 клієнт'
                },
            ],
        },
        {
            subtitle: 'Додатковий функціонал:',
            subtext: 'Ви також можете розширити свій додаток додатковими функціями (наприклад онлайн чат,бронювання кімнат,тощо), які будуть оплачуватись окремо ( фіксована ціна на місяць). Такий варіант обговорюється окремо.',
        },
        {
            title: 'Фіксована абонентська плата:',
            items: [
                {
                    title: 'Basic',
                    items: [
                        {
                            text: 'Базовий список функціоналу мобільного додатку для готелю',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Підтримка та навчання персоналу',
                            bullet: 'checked'
                        },
                        {
                            text: 'Онлайн-бронювання: швидкий перегляд номерів, цін та послуг із можливістю бронювання в додатку.',
                            bullet: 'crossed'
                        },
                        {
                            text: 'Чат із персоналом: швидка комунікація для уточнень, персоналізації сервісу та індивідуальних пропозицій.',
                            bullet: 'crossed'
                        },
                    ],
                    price: '2000 ГРН',
                    period: 'на місяць',
                    button: 'Замовити'
                },
                {
                    title: 'Standart',
                    items: [
                        {
                            text: 'Базовий список функціоналу мобільного додатку для готелю',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Підтримка та навчання персоналу',
                            bullet: 'checked'
                        },
                        {
                            text: 'Онлайн-бронювання: швидкий перегляд номерів, цін та послуг із можливістю бронювання в додатку.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Чат із персоналом: швидка комунікація для уточнень, персоналізації сервісу та індивідуальних пропозицій.',
                            bullet: 'checked'
                        },
                    ],
                    price: '3500 ГРН',
                    period: 'на місяць',
                    button: 'Замовити'
                },
                {
                    title: 'Pro',
                    items: [
                        {
                            text: 'Список функціоналу мобільного додатку для готелю пакету Standart',
                            bullet: 'checked'
                        },
                        {
                            text: 'Друк QR-кодів з меню: можливість розміщення кодів у ресторані, СПА або кімнатах для швидкого доступу до меню та оформлення замовлення через додаток.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Оплати онлайн: гості можуть оплачувати послуги та бронювання безпосередньо у додатку.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Розробка індивідуальноі програми лояльності для гостей у додатку.',
                            bullet: 'checked'
                        },
                    ],
                    price: '5500 ГРН',
                    period: 'на місяць',
                    button: 'Замовити'
                }
            ],
            note: 'Базовий список функціоналу мобільного додатку для готелю',
            subitems: [
                {
                    title: 'Особистий кабінет гостя:',
                    text: ' гість отримує доступ до персоналізованої інформації про бронювання та послуги, доступ через QR Code.'
                },
                {
                    title: 'Замовлення послуг:',
                    text: 'можливість замовити прибирання, їжу та напої, викликати таксі чи надіслати запит на ресепшн із вибором часу й дат.'
                },
                {
                    title: 'Резервація додаткових послуг:',
                    text: 'можливість замовити спа,забронювати сауну чи будь яку додаткову послугу готелю.'
                },
                {
                    title: 'Реклама послуг і партнерів:',
                    text: 'банери на головному екрані для просування ваших послуг або продуктів партнерів.'
                },
                {
                    title: 'Відгуки гостей:',
                    text: 'можливість залишити відгук після кожної послуги, збереження інформації для аналізу та зв\'язку з клієнтом.'
                },
                {
                    title: 'Робочий кабінет співробітників:',
                    text: 'усі запити гостей надходять у персоналізований кабінет із пуш-сповіщеннями для контролю.'
                },
                {
                    title: 'Аналітика та контроль:',
                    text: 'моніторинг виконання запитів, аналіз швидкості роботи персоналу.'
                },
                {
                    title: 'База  даних гостей:',
                    text: 'Відсліковуйте кіл-ть гостей на місяць/тиждень/день. Зберігайте іх дані та преференціі для забезпечення найкращого сервісу.'
                },
            ]
        },
        {

        }
    ],
}