<template>
  <div v-for="n in array3" :key="n" class="section__list-item third slide">
    <div class="slider-panel">
    <div class="part1">
      <p class="section__list-item__title-center">{{ $t(`home.payments.section.4.items.${n}.title`) }}</p>
      <ul class="section__list-text img-marker">
        <li v-for="k in array4" :key="k"
            :class="{'section__list-text-item ':true,[$t(`home.payments.section.4.items.${n}.items.${k}.bullet`)]: true}">
          <span :class="{'bullet':true}"></span>
          <span class="section__list-text-item__text text">
              {{ $t(`home.payments.section.4.items.${n}.items.${k}.text`) }}
            </span>
        </li>
      </ul>
    </div>

    <div class="part2">
      <div>
        <p class="section__list-item__title-center">{{ $t(`home.payments.section.4.items.${n}.price`) }}</p>
        <p class="section__list-item__subtitle-center">{{ $t(`home.payments.section.4.items.${n}.period`) }}</p>
      </div>
      <base-button :text="$t(`home.payments.section.4.items.${n}.button`)"
                   @click="redirectToTg($t(`home.payments.section.4.items.${n}.title`)+'_fee')"/>
    </div>
    </div>
  </div>
</template>
<script setup>
import BaseButton from "@/components/Button/BaseButton.vue";
import {useArray} from "@/composable/useArray";
import {useButton} from "@/composable/useButton";

const { array4, array3, array8 } = useArray()
const { redirectToTg/*, redirectToViber*/ } = useButton();
</script>

<style lang="scss" scoped>
.desktop .section {
  &__list {
    @extend .flex-row;
    margin: 1vw auto 2vw;

    flex-wrap: wrap;
    gap: 1.5vw;
    &-item {
      @extend .flex-col;
      width: calc((100% - 1.5vw * 3) / 4);
      padding: 1.25vw;
      border: 2px solid $primary;
      background-color: $light-grey;
      box-sizing: border-box;
      border-radius: 20px;

      &.third{
        width: calc((100% - 1.5vw * 3) / 3) !important;
        .slider-panel{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: stretch;

        }
      }


      &.third .section__list-item__title-center{
        @include font(2.38vw, 140%, $text, 600,$align: center);
      }

      &__title {
        @include font(1.4vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
        &-center{
          @include font(1.4vw, 140%, $text, 600,$align: center);
          margin-bottom: 1.05vw;
        }
      }

      &__subtitle-center{
        @include font(1.19vw, 140%, $text, 500,$align: center);
        margin-bottom: 1.05vw;
      }


      &__text {
        @include font(1.4vw, 140%, $text, 500);
        &-center{
          @include font(1.4vw, 140%, $text, 500,$align: center);
          margin-bottom: 1.05vw;
        }
      }


    }

  }
  &__list-text.img-marker {
    list-style-type: none;
    padding-left: 0 !important;
    .section__list-text-item{
      display: flex;
      align-items: center;
      margin-bottom: 2em!important;
      padding: 0;
      margin: 0;
      &__title {
        @include font-24($weight: 600);
      }
      &__text {
        @include font-24($weight: 500);
      }

      .text {
        flex: 9;
        margin-right: 10%;
        @include font(1.2rem);

      }
      &.crossed {
        .text {
          color: $text-light-grey;
        }
      }

       .bullet{
        width: 2.43vw;
        height: 2.43vw;
        display: inline-block;
        background-position: left bottom;
        background-size: contain;
        background-repeat: no-repeat;
        flex:2;
        justify-self: center;
      }
      .text {
        flex: 9;
        margin-right: 10%;
        @include font(1.2rem);

      }
      &.crossed {
        .bullet {
          background-image: url($crossed-item);
        }
        .text {
          color: $text-light-grey;
        }
      }

      &.checked .bullet {
        background-image: url($checked-item);
      }

      &.checked-star .bullet{
        height: 2.84vw;
        background-image: url($checked-starred-item);
      }
    }
  }

}
.mobile .section {

  &__list {
   /* @extend .flex-row;
    margin: 1vw auto 2vw;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.5vw;*/
    .slider-panel{
      /*width: 100vw;*/
      height: 100%;
      @extend .flex-col;
      margin: 1vw auto 2vw;
      /*width: 100%;*/
      flex-wrap: wrap;
      /*justify-content: space-between;*/
      gap: 1.5vw;
      .part1{
        justify-self: start;
        flex-grow: 1;
      }
      .part2{
        /*flex-grow: 1;*/
        justify-self: end;
      }
    }
    &-item {
      @extend .flex-col;
      width: 55.3488vw;
      max-width: 55.3488vw;
      /*height: 111.3953vw;*/
      padding: 1.25vw;
      margin-right: 3vw;
      border: 2px solid $primary;
      background-color: $light-grey;
      box-sizing: border-box;
      border-radius: 2.3256vw;
      .base-button{
        /*width: 50vw;*/
        display: none;
      }

      &__title {
        @include font(5.5814vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
        &-center{
          @include font(5.5814vw, 140%, $text, 600,$align: center);
          margin-bottom: 1.05vw;
        }
      }

      &__subtitle-center{
        @include font(3.2558vw, 140%, $text, 500,$align: center);
        margin-bottom: 1.05vw;
      }


      &__text {
        @include font(1.4vw, 140%, $text, 500);
        &-center{
          @include font(1.4vw, 140%, $text, 500,$align: center);
          margin-bottom: 1.05vw;
        }
      }


    }

  }
  &__list-text.img-marker {
    height: 90%;
    position: relative;
    display: flex  ;
    flex-direction: column;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0 !important;
    width: calc(55.3488vw);
    margin: 0;
    .section__list-text-item{
      width: calc(55.3488vw);
      display: flex;
      align-items: center;
      margin-bottom: 3vw!important;
      padding: 0;
      &.crossed {
        .text {
          color: $text-light-grey;
        }
      }
      .bullet{
        width: 4.6512vw;
        height: 4.6512vw;
        display: inline-block;
        background-position: left bottom;
        background-size: contain;
        background-repeat: no-repeat;
        flex:2;
        justify-self: center;
      }
      .text {
        flex: 14;
        margin-right: 10%;
        @include font(3.7209vw);

      }
      &.crossed {
        .bullet {
          background-image: url($crossed-item);
        }
        .text {
          color: $text-light-grey;
        }
      }

      &.checked .bullet {
        background-image: url($checked-item);
      }

      &.checked-star .bullet{
        height: 6.9767vw;
        background-image: url($checked-starred-item);
      }
    }
  }

}
</style>