export const payments = {
    title: 'FLEXIBLE PAYMENT OPTIONS',
    section: [
        {
            title1: 'Pay-as-you-go:',
            title2: '',
            subtitle: 'What features do you get?',
            items: [
                {
                    title: 'Guest Personal Account:',
                    text: 'Guests gain access to personalized booking and service information, accessible via QR Code.'
                },
                {
                    title: 'Service Ordering:',
                    text: 'Ability to order cleaning, food and drinks, request a taxi, or send a request to the reception with a time and date selection.'
                },
                {
                    title: 'Additional Service Reservations:',
                    text: 'Ability to book a spa, reserve a sauna, or any other additional hotel service.'
                },
                {
                    title: 'Service and Partner Advertising:',
                    text: 'Banners on the main screen to promote your services or partner products.'
                },
                {
                    title: 'Guest Reviews:',
                    text: 'Ability to leave a review after each service, storing information for analysis and customer feedback.'
                },
                {
                    title: 'Employee Dashboard:',
                    text: 'All guest requests are sent to a personalized dashboard with push notifications for monitoring.'
                },
                {
                    title: 'Analytics and Monitoring:',
                    text: 'Monitoring request execution, analyzing staff efficiency.'
                },
                {
                    title: 'Guest Database:',
                    text: 'Track the number of guests per month/week/day. Store their data and preferences to ensure top-notch service.'
                },
            ],
        },
        {
            subtitle: 'Additional Benefits:',
            subtext: 'We not only fill the app with information about your hotel but also provide full support:',
            subtext2: 'With us, your staff is ready to work, and all issues are resolved promptly!',
            items: [
                {
                    title: 'Staff Training:',
                    text: 'We conduct training sessions for employees to ensure confident app usage.'
                },
                {
                    title: 'Setup Assistance:',
                    text: 'We help with setup and account creation for your team.'
                },
                {
                    title: 'Daily Support:',
                    text: 'Always available, resolving issues quickly and providing consultations, regardless of the selected package.'
                },
            ],
        },
        {
            subtitle: 'Pricing',
            subtext: 'You don’t have to pay if your guests don’t use the app. You only pay for actual usage.',
            items: [
                {
                    title: 'Up to 100 Clients:',
                    text: 'For example: if 80 hotel guests use the app in a month, the rate is 40 UAH per client.',
                    price: '40 UAH / 1 client'
                },
                {
                    title: 'From 100 to 250 Clients:',
                    text: 'For example: if 170 hotel guests use the app in a month, the rate is 30 UAH per client.',
                    price: '30 UAH / 1 client'
                },
                {
                    title: 'Over 250 Clients:',
                    text: 'For example: if 270 hotel guests use the app in a month, the rate is 20 UAH per client.',
                    price: '20 UAH / 1 client'
                },
                {
                    title: 'Over 500 Clients:',
                    text: 'For example: if 550 hotel guests use the app in a month, the rate is 10 UAH per client.',
                    price: '10 UAH / 1 client'
                },
            ],
        },
        {
            subtitle: 'Additional Features:',
            subtext: 'You can also expand your app with additional features (e.g., online chat, room booking, etc.), which will be charged separately (fixed monthly price). This option is discussed individually.',
        },
        {
            title: 'Fixed Subscription Fee:',
            items: [
                {
                    title: 'Basic',
                    items: [
                        {
                            text: 'Basic feature list of the hotel mobile app',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Staff support and training',
                            bullet: 'checked'
                        },
                        {
                            text: 'Online booking: quick view of rooms, prices, and services with the ability to book in the app.',
                            bullet: 'crossed'
                        },
                        {
                            text: 'Chat with staff: quick communication for clarifications, service personalization, and special offers.',
                            bullet: 'crossed'
                        },
                    ],
                    price: '2000 UAH',
                    period: 'per month',
                    button: 'Order'
                },
                {
                    title: 'Standard',
                    items: [
                        {
                            text: 'Basic feature list of the hotel mobile app',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Staff support and training',
                            bullet: 'checked'
                        },
                        {
                            text: 'Online booking: quick view of rooms, prices, and services with the ability to book in the app.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Chat with staff: quick communication for clarifications, service personalization, and special offers.',
                            bullet: 'checked'
                        },
                    ],
                    price: '3500 UAH',
                    period: 'per month',
                    button: 'Order'
                },
                {
                    title: 'Pro',
                    items: [
                        {
                            text: 'Feature list of the Standard package hotel mobile app',
                            bullet: 'checked'
                        },
                        {
                            text: 'QR code menu printing: ability to place QR codes in the restaurant, SPA, or rooms for quick menu access and ordering via the app.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Online payments: guests can pay for services and bookings directly in the app.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Development of an individual loyalty program for guests in the app.',
                            bullet: 'checked'
                        },
                    ],
                    price: '5500 UAH',
                    period: 'per month',
                    button: 'Order'
                }
            ],
            note: 'Basic functionality list for the hotel mobile app',
            subitems: [
                {
                    title: 'Guest personal account:',
                    text: 'The guest gets access to personalized information about bookings and services, accessible via QR Code.'
                },
                {
                    title: 'Service orders:',
                    text: 'The ability to order cleaning, food and drinks, request a taxi, or send a request to the reception with selected time and date.'
                },
                {
                    title: 'Reservation of additional services:',
                    text: 'The ability to order a spa, book a sauna, or any other additional hotel service.'
                },
                {
                    title: 'Advertising of services and partners:',
                    text: 'Banners on the main screen to promote your services or products of partners.'
                },
                {
                    title: 'Guest reviews:',
                    text: 'The ability to leave a review after each service, storing information for analysis and communication with the client.'
                },
                {
                    title: 'Employee workspace:',
                    text: 'All guest requests come to a personalized workspace with push notifications for control.'
                },
                {
                    title: 'Analytics and control:',
                    text: 'Monitoring of request execution, analysis of staff work speed.'
                },
                {
                    title: 'Guest database:',
                    text: 'Track the number of guests per month/week/day. Store their data and preferences to ensure the best service.'
                },
            ]
        }
    ],
}
