<template>
  <div class="section">
    <div class="section__content">
      <p class="title">{{ $t('home.about_title') }}</p>
      <p v-html="$t('home.about_text')" class="section__content-text"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.section {
  position: relative;
}

.desktop .section {
  @extend .row-container;
  margin: 5.3vw 0;

  &__content {
    /*width: 43.58vw;*/

    .title {
      @include font-60($align: center, $weight: 600);
    }

    &-text {
      @include font-26($align: center);
    }
  }
}

.mobile .section {
  @extend .flex-col;
  position: relative;
  margin: 4.1vw 0 5.7vw;
  border-radius: 8px;

  &__content {
    /*width: 43.58vw;*/

    .title {
      @include font-30($align: center, $weight: 600);
    }

    &-text {
      @include font-18($align: left);
    }
  }
}
</style>
