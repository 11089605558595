<template>
  <div class="section">
    <div class="section__left">
      <p class="title">{{ $t('home.form_title') }}</p>
    </div>
    <div class="section__right" :key="key">
      <p class="section__right-title">{{ $t('home.form_hint') }}</p>
      <form-input v-model="name" :label="$t('home.form_name')" />
      <form-input v-model="phone" :label="$t('home.form_phone')" />
      <base-button :text="$t('buttons.send')" @click="onSendClick" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import FormInput from "@/components/Form/FormInput.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRender } from "@/composable/useRender";

const { renderNotification } = useRender();

const url = process.env.NODE_ENV === 'development' ? `http://localhost:3000` : `https://demochat.vipmanage.org/api`;
const fullUrl = `${url}/request/presentation`;

const name = ref('');
const phone = ref('');
const key = ref(0);

const onSendClick = async () => {
  try {
    await fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({ userName: name.value, phone: phone.value })
    });
    renderNotification('successful_data_send');
    name.value = '';
    phone.value = '';
    key.value++;
  } catch (e) {
    console.log(e);
    renderNotification('error_data_send');
  }
};
</script>

<style scoped lang="scss">
.section {
  &__left, &__right {
    @extend .flex-col;
  }
}

.desktop .section {
  @extend .row-container;
  margin: 1.32vw 0 7vw;

  &__left {
    @extend .flex-col;
    width: 43.32vw;
    margin-right: 8.4vw;
  }

  &__right {
    @extend .flex-col;
    width: 40vw;

    &-title {
      max-width: 80%;
      @include font-18($color: $grey, $weight: 400);
      margin-bottom: 2vw;
    }
  }

  .field {
    margin-bottom: 1.32vw;
  }

  .base-button {
    margin-top: 0.75vw;
  }
}

.mobile .section {
  @extend .flex-col;
  margin: 5.7vw 0 5.7vw;

  .title {
    margin-bottom: 4.1vw;
  }

  &__right {
    &-title {
      @include font-16($weight: 400, $height: 120%);
      margin-bottom: 4.1vw;
    }
  }

  .field {
    margin-bottom: 6.15vw;
  }
}
</style>
