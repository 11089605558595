<script setup>
import {ref, computed, onMounted} from "vue";
import { vSlide } from '@/directives/slide';

const currentIndex = ref(0);

const props = defineProps({
  show_indicators: {
    type: Boolean,
    default: false
  },
  show_controls: {
    type: Boolean,
    default: false
  },
  slide_percent: {
    type: Number,
    default: 100
  }
});
const width =  computed(() => slidesEnvelope.value.querySelector(".slide:first-child")?.offsetWidth);
// ✅ Обчислюємо стиль для зсуву слайдів
// const sliderStyle = computed(() => ({
//   transform: `translateX(-${currentIndex.value * props.slide_percent}%)`
// }))
const sliderStyle = computed(() => {
  const totalWidth = slideWidths.value.slice(0, currentIndex.value).reduce((acc, width) => acc + width, 0);
  return {
    transform: `translateX(-${totalWidth}px)`
  };
});

// ✅ Функція для перемикання слайдів
const goToSlide = (index) => {
  currentIndex.value = index;
};

const onSlide = (direction) => {
  if (direction === 'right') {
    if(currentIndex.value < (slideCount.value-1)) goToSlide(currentIndex.value + 1)
  } else {
    if(currentIndex.value > 0) goToSlide(currentIndex.value - 1)
  }
};
const slides = ref();
const slideCount = computed(() => slides.value?.length ?? 0);
const slidesEnvelope = ref();
const slideWidths = ref([]);

onMounted(() => {
  slides.value = slidesEnvelope.value.querySelectorAll(".slide");
  slideWidths.value = Array.from(slides.value).map(slide => slide.offsetWidth);
  console.log('Widths of slides:', slideWidths.value);
})
</script>

<template>
  <div class="slider"  v-slide="onSlide">
    <div ref="slidesEnvelope" class="slides-envelope" :style="sliderStyle">
      <slot></slot>
    </div>

    <!-- ✅ Пагінація -->
    <template v-if="$props.show_indicators">
      <div class="pagination" v-if="slideCount > 1">
        <button class="pagination__indicator"
                v-for="(_, index) in slideCount"
                :key="index"
                :class="{ active: index === currentIndex }"
                @click="goToSlide(index)"
        />
      </div>
    </template>
    <template  v-if="$props.show_controls">
      <div class="pagination__controls" v-if="slideCount > 1">
        <button class="pagination__controls__button-prev"
                @click="onSlide('left')"
        />
        <button class="pagination__controls__button-next"
                @click="onSlide('right')"
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss" >

$inner-height: inherit;
$slider-height: calc($inner-height + 30px);


.slider {
  width: inherit;
  height: $slider-height;
  overflow: hidden;
  /*  overflow-y: visible;*/
  position: relative;


  /* Контейнер зі слайдами */
  .slides-envelope {
    width: inherit;
    height: $inner-height;
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  /* Окремий слайд */
  .slide {
    float: left;
    width: 100%;
    height: $inner-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    flex-shrink: 0;
  }

  /* Пагінація */
  .pagination {
    margin-top: 4vw;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-self: center;

    &__indicator {
      width: 10.2564vw; /* Довжина смужки */
      box-sizing: border-box;
      height: 3px; /* Товщина смужки */
      background: $slider-grey; /* Неактивний колір */
      border: none;
      cursor: pointer;
      transition: background 0.3s ease-in-out;

    }

    button.active {
      background: $primary; /* Активний колір */
    }

    &__controls {
      gap: 16px;
      margin-top: 4vw;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      button {
        width: 9.3023vw;
        height: 9.3023vw;
        box-sizing: border-box;
        background-color: transparent;
        background-position:  center;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;
        transition: background 0.3s ease-in-out;

      }
      &__button-prev{
        background-image: url($arrow-prev);
      }
      &__button-next{
        background-image: url($arrow-next);
      }
    }
  }
}
</style>
