<template>
  <div class='field'>
    <p v-if="label" class="field__label">{{ label }}</p>
    <div class="field__input">
      <input v-model='value' ref='input' spellcheck='false' :placeholder="placeholder" @input='onInput' />
    </div>
    <p v-if='hint' class='field__hint'>{{ hint }}</p>
  </div>
</template>

<script lang='ts' setup>
import { ref } from 'vue';

const props = defineProps({
  label: String,
  hint: String,
  placeholder: String,
  modelValue: String,
});
const emits = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);

const onInput = () => emits('update:modelValue', value.value);
</script>

<style lang='scss' scoped>
.field {
  @extend .flex-col;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: max-content;
  height: max-content;

  &__input {
    @extend .flex-row, .pointer;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: $secondary;
    border-radius: 60px;
    box-sizing: border-box;
    border: 1.5px solid transparent;
    transition-duration: 0.2s;

    &:focus, &:has(input:focus) {
      border-color: $primary;
    }
  }

  &__label {
    margin-bottom: 1rem;
  }

  input {
    font-family: Montserrat, sans-serif;
    font-weight: 500 !important;
    resize: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.desktop .field {
  &__input {
    height: 4.63vw;
    min-height: 4.63vw;
    padding: 15px 1.05vw;
  }

  &__label, input {
    @include font-20();
  }
}

.mobile .field {
  &__input {
    height: 13.0233vw;
    min-height: 13.0233vw;
    padding: 15px 4.1vw;
  }

  &__label, input {
    @include font-16($height: 120%, $weight: 600);
  }
}
</style>
