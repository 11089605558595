import { useRouter } from "vue-router";
import { useRender } from "@/composable/useRender";

export const useButton = () => {
  const { push } = useRouter();
  const { renderNotification } = useRender();

  // const redirectToTg = (startMessage='') => window.open(`https://t.me/katgorskaja${(startMessage.length>0? `?start=\`${startMessage}\``)}`, '_blank');
  const redirectToTg = (startMessage = '') => {
    /* startMessage will work only for bots, not for private users */
    const encodedMessage = encodeURIComponent(startMessage);
    window.open(`https://t.me/katgorskaja${startMessage.length > 0 ? `?start=${encodedMessage}` : ''}`, '_blank');
  };

  const redirectToWatsapp = () => window.open(`https://wa.me/380638215345`, '_blank');

  const redirectToViber = () => window.open('viber://chat?number=+380638215345', '_blank');
  // const redirectToTg = () => window.open('https://t.me/umtrnBot', '_blank');

  const goToHotelPage = () => push('/hotel');

  const copyText = (text: string) => navigator.clipboard.writeText(text).then(() => setTimeout(() => renderNotification('copied'), 10));

  const redirectToEmail = () => window.open('mailto:sales@smarthotelservice.com', '_blank');
  const goToFacebook = () => window.open('https://www.facebook.com/share/etC75r4qk2ENR3oL/?mibextid=LQQJ4d', '_blank');

  const goToInstagram = () => window.open('https://www.instagram.com/smart.hotel.service?igsh=bWlyM3VqbGp0MW5v&utm_source=qr', '_blank');

  return { redirectToTg, redirectToViber, redirectToWatsapp, goToHotelPage, copyText, redirectToEmail, goToFacebook, goToInstagram };
};
