<script setup lang="ts">
import {useArray} from "@/composable/useArray";
const { array4 } = useArray()
</script>

<template>

  <div class="section">
    <p class="section__subtitle">{{ $t('home.payments.section.2.subtitle') }}</p>
    <p class="section__text">{{ $t('home.payments.section.2.subtext') }}</p>
    <div class="section__list">
      <div v-for="n in array4" :key="n" class="section__list-item__price">
        <p class="section__list-item__title-center">{{ $t(`home.payments.section.2.items.${n}.title`) }}</p>
        <p class="section__list-item__text">{{ $t(`home.payments.section.2.items.${n}.text`) }}</p>
        <p class="section__list-item__text-center price-shape">{{ $t(`home.payments.section.2.items.${n}.price`) }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section {
  @extend .flex-col;

  #section1__text{
    margin-bottom: 0;
  }
}

.desktop .section {
  margin: 1vw 0 0;

  .title {
    margin-bottom: 1.58vw;
  }
  &__subtitle{
    @include font-28($weight: 600, $height: 300%);
  }

  &__text {
    @include font-24($height: 150%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list {
    margin: 1vw auto 2vw;
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1.5vw;
    &-item {
       @extend .flex-col;
      width: calc((100% - 1.5vw * 3) / 4);
      padding: 1.25vw;
      border: 2px solid $primary;
      background-color: $light-grey;
      border-radius: 20px;
       box-sizing: border-box;
       &.third .section__list-item__title-center{
           @include font(2.38vw, 140%, $text, 600,$align: center);
       }

      &__title {
        @include font(1.4vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
        &-center{
          @include font(1.4vw, 140%, $text, 600,$align: center);
          margin-bottom: 1.05vw;
        }
      }

      &__subtitle-center{
        @include font(1.19vw, 140%, $text, 500,$align: center);
        margin-bottom: 1.05vw;
      }


      &__text {
        @include font(1.4vw, 140%, $text, 500);
        &-center{
          @include font(1.4vw, 140%, $text, 500,$align: center);
          margin-bottom: 1.05vw;
        }
      }


    }
    &-item__price{
      /*@extend .flex-col;*/
      @extend .section__list-item;
      justify-content: center;
      .price-shape{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        width: 15vw;/*226px;*/
        height: 6.64vw; /* 100px;*/
        margin: 1.5vw 0 0;
        background: transparent url($price-shape) center no-repeat;
        background-size: contain;

        font-weight: bold;
      }
    }
  }
}

.mobile .section {
  margin: 1vw 0;

  .title {
    margin-bottom: 1.58vw;
  }
  &__subtitle{
    @include font-24($weight: 600, $height: 300%);
  }

  &__text {
    @include font-18($height: 150%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list {
    margin: 1vw auto 2vw;
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1.5vw;
    &-item {
      @extend .flex-col;
      width: calc((100% - 1.5vw) / 2);
      padding: 2.5vw;
      border: 2px solid $primary;
      background-color: $light-grey;
      border-radius: 20px;
      box-sizing: border-box;

      &__title {
        @include font(3.7209vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
        &-center{
          min-height: 3em;

          @include font(3.7209vw, 140%, $text, 600,$align: center);
          margin-bottom: 1.05vw;
        }
      }
      &__text {
        @include font(3.2558vw, 140%, $text, 500);
        &-center{
          @include font(3.2558vw, 140%, $text, 500,$align: center);
          margin-bottom: 1.05vw;
        }
      }


    }
    &-item__price{
      /*@extend .flex-col;*/
      @extend .section__list-item;
      justify-content: center;
      margin: 0;
      .price-shape{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        width: 16.1628vw;/*146.9px;*/
        padding: 0 9vw; /*34.1628vw*/;/*146.9px;*/
        height: 15.1163vw; /* 65px;*/
        margin: 1.5vw 0 0;
        background: transparent url($price-shape) center no-repeat;
        background-size: contain;
        font-weight: bold;

      }
    }
  }

}
</style>