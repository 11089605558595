export const payments = {
    title: 'ELASTYCZNE OPCJE PŁATNOŚCI',
    section: [
        {
            title1: 'Płatność zgodnie',
            title2: 'z użytkowaniem:',
            subtitle: 'Jakie funkcje otrzymujesz?',
            items: [
                {
                    title: 'Osobiste konto gościa:',
                    text: 'Goście mają dostęp do spersonalizowanych informacji o rezerwacjach i usługach, dostępnych poprzez kod QR.'
                },
                {
                    title: 'Zamawianie usług:',
                    text: 'Możliwość zamówienia sprzątania, jedzenia i napojów, wezwania taksówki lub wysłania prośby do recepcji z wyborem czasu i daty.'
                },
                {
                    title: 'Rezerwacja dodatkowych usług:',
                    text: 'Możliwość rezerwacji spa, sauny lub dowolnej dodatkowej usługi hotelowej.'
                },
                {
                    title: 'Reklama usług i partnerów:',
                    text: 'Banery na ekranie głównym do promowania usług hotelu lub produktów partnerów.'
                },
                {
                    title: 'Opinie gości:',
                    text: 'Możliwość pozostawienia opinii po każdej usłudze, przechowywanie informacji do analizy i kontaktu z klientem.'
                },
                {
                    title: 'Panel pracowników:',
                    text: 'Wszystkie prośby gości trafiają do spersonalizowanego panelu z powiadomieniami push dla monitorowania.'
                },
                {
                    title: 'Analiza i monitorowanie:',
                    text: 'Monitorowanie realizacji zamówień, analiza efektywności personelu.'
                },
                {
                    title: 'Baza danych gości:',
                    text: 'Śledzenie liczby gości w miesiącu/tygodniu/dniu. Przechowywanie ich danych i preferencji w celu zapewnienia najwyższej jakości obsługi.'
                },
            ],
        },
        {
            subtitle: 'Dodatkowe korzyści:',
            subtext: 'Nie tylko uzupełniamy aplikację informacjami o Twoim hotelu, ale także zapewniamy pełne wsparcie:',
            subtext2: 'Z nami Twój personel jest gotowy do pracy, a wszystkie problemy są rozwiązywane na bieżąco!',
            items: [
                {
                    title: 'Szkolenie personelu:',
                    text: 'Przeprowadzamy szkolenia dla pracowników, aby mogli pewnie korzystać z aplikacji.'
                },
                {
                    title: 'Pomoc w konfiguracji:',
                    text: 'Pomagamy w konfiguracji i tworzeniu kont dla zespołu.'
                },
                {
                    title: 'Codzienne wsparcie:',
                    text: 'Zawsze dostępni, szybko rozwiązujemy problemy i zapewniamy konsultacje, niezależnie od wybranego pakietu.'
                },
            ],
        },
        {
            subtitle: 'Cennik',
            subtext: 'Nie musisz płacić, jeśli Twoi goście nie korzystają z aplikacji. Płacisz tylko za faktyczne użytkowanie.',
            items: [
                {
                    title: 'Do 100 klientów:',
                    text: 'Na przykład: jeśli 80 gości hotelowych korzysta z aplikacji w ciągu miesiąca, stawka wynosi 40 UAH na klienta.',
                    price: '40 UAH / 1 klient'
                },
                {
                    title: 'Od 100 do 250 klientów:',
                    text: 'Na przykład: jeśli 170 gości hotelowych korzysta z aplikacji w ciągu miesiąca, stawka wynosi 30 UAH na klienta.',
                    price: '30 UAH / 1 klient'
                },
                {
                    title: 'Powyżej 250 klientów:',
                    text: 'Na przykład: jeśli 270 gości hotelowych korzysta z aplikacji w ciągu miesiąca, stawka wynosi 20 UAH na klienta.',
                    price: '20 UAH / 1 klient'
                },
                {
                    title: 'Powyżej 500 klientów:',
                    text: 'Na przykład: jeśli 550 gości hotelowych korzysta z aplikacji w ciągu miesiąca, stawka wynosi 10 UAH na klienta.',
                    price: '10 UAH / 1 klient'
                },
            ],
        },
        {
            subtitle: 'Dodatkowe funkcje:',
            subtext: 'Możesz także rozszerzyć swoją aplikację o dodatkowe funkcje (np. czat online, rezerwacja pokoi itp.), które będą rozliczane osobno (stała miesięczna opłata). Opcja ta jest omawiana indywidualnie.',
        },
        {
            title: 'Stała opłata abonamentowa:',
            items: [
                {
                    title: 'Basic',
                    items: [
                        {
                            text: 'Podstawowa lista funkcji aplikacji mobilnej hotelu',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Wsparcie i szkolenie personelu',
                            bullet: 'checked'
                        },
                        {
                            text: 'Rezerwacja online: szybki podgląd pokoi, cen i usług z możliwością rezerwacji w aplikacji.',
                            bullet: 'crossed'
                        },
                        {
                            text: 'Czat z personelem: szybka komunikacja w celu doprecyzowania, personalizacji usług i ofert indywidualnych.',
                            bullet: 'crossed'
                        },
                    ],
                    price: '2000 UAH',
                    period: 'miesięcznie',
                    button: 'Zamów'
                },
                {
                    title: 'Standard',
                    items: [
                        {
                            text: 'Podstawowa lista funkcji aplikacji mobilnej hotelu',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Wsparcie i szkolenie personelu',
                            bullet: 'checked'
                        },
                        {
                            text: 'Rezerwacja online: szybki podgląd pokoi, cen i usług z możliwością rezerwacji w aplikacji.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Czat z personelem: szybka komunikacja w celu doprecyzowania, personalizacji usług i ofert indywidualnych.',
                            bullet: 'checked'
                        },
                    ],
                    price: '3500 UAH',
                    period: 'miesięcznie',
                    button: 'Zamów'
                },
                {
                    title: "Pro",
                    items: [
                        {
                            "text": "Lista funkcjonalności aplikacji mobilnej dla hoteli pakietu Standard",
                            "bullet": "checked"
                        },
                        {
                            "text": "Drukowanie kodów QR z menu: możliwość umieszczenia kodów w restauracji, SPA lub pokojach dla szybkiego dostępu do menu i składania zamówień przez aplikację.",
                            "bullet": "checked"
                        },
                        {
                            "text": "Płatności online: goście mogą opłacać usługi i rezerwacje bezpośrednio w aplikacji.",
                            "bullet": "checked"
                        },
                        {
                            "text": "Opracowanie indywidualnego programu lojalnościowego dla gości w aplikacji.",
                            "bullet": "checked"
                        }
                    ],
                    price: "5500 UAH",
                    period: "miesięcznie",
                    button: "Zamów"
                }

            ],
            note: "Podstawowa lista funkcjonalności aplikacji mobilnej dla hotelu",
            subitems: [
                {
                    title: "Panel gościa:",
                    text: "Gość ma dostęp do spersonalizowanych informacji o rezerwacjach i usługach, dostęp poprzez kod QR."
                },
                {
                    title: "Zamawianie usług:",
                    text: "Możliwość zamówienia sprzątania, jedzenia i napojów, wezwania taksówki lub wysłania zapytania do recepcji z wyborem czasu i daty."
                },
                {
                    title: "Rezerwacja dodatkowych usług:",
                    text: "Możliwość zamówienia SPA, rezerwacji sauny lub dowolnej innej usługi hotelowej."
                },
                {
                    title: "Reklama usług i partnerów:",
                    text: "Banery na ekranie głównym do promowania usług hotelu lub produktów partnerów."
                },
                {
                    title: "Opinie gości:",
                    text: "Możliwość pozostawienia opinii po każdej usłudze, zapisywanie informacji do analizy i kontaktu z klientem."
                },
                {
                    title: "Panel pracowników:",
                    text: "Wszystkie zapytania gości trafiają do spersonalizowanego panelu z powiadomieniami push dla kontroli."
                },
                {
                    title: "Analiza i kontrola:",
                    text: "Monitorowanie realizacji zapytań, analiza szybkości pracy personelu."
                },
                {
                    title: "Baza danych gości:",
                    text: "Śledzenie liczby gości miesięcznie/tygodniowo/dziennie. Przechowywanie ich danych i preferencji w celu zapewnienia najlepszej obsługi."
                }
            ]
        }
    ],
}
