<template>
  <div class="section__desktop">
    <p class="title">{{ $t('home.points_title') }}</p>
    <p class="section__text">{{ $t('home.points_text') }}</p>
    <div class="section__list">
      <div v-for="n in 12" :key="n" class="section__list-item">
        <p class="section__list-item__title">{{ $t(`home.points_item_${ n }_title`) }}</p>
        <p class="section__list-item__text">{{ $t(`home.points_item_${ n }_text`) }}</p>
      </div>
    </div>
  </div>

  <div class="section__mobile">
    <p class="title">{{ $t('home.points_title') }}</p>
    <p class="section__text">{{ $t('home.points_text') }}</p>

    <div class=" slider-container">
      <text-content-slider show_controls>
          <div v-for="n in 12" :key="n" class="slide">
            <div class="slider-panel">
              <div class="slider-panel-item">
                <p class="slider-panel-item__title">{{ $t(`home.points_item_${ (n) }_title`) }}</p>
                <p class="slider-panel-item__text">{{ $t(`home.points_item_${ (n) }_text`) }}</p>
              </div>

            </div>
          </div>
      </text-content-slider>
    </div>

  </div>

</template>

<script setup lang="ts">
import {useArray} from "@/composable/useArray";
const { array12 } = useArray()
import TextContentSlider from "@/components/TextContentSlider.vue";
</script>

<style scoped lang="scss">
.section {
  @extend .flex-col;

  &__list {
    &-item {
      @extend .flex-col;
      background-color: $light-grey;
      box-sizing: border-box;
    }
  }
}

.desktop .section {
  &__mobile{
    display: none;
  }
  margin-bottom: 6.6vw;

  .title {
    margin-bottom: 1.58vw;
  }

  &__text {
    @include font-20($height: 150%);
    margin-bottom: 3.17vw;
  }

  &__list {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1.5vw;

    &-item {
      width: calc((100% - 1.5vw * 3) / 4);
      padding: 1.25vw;
      border: 4px dashed $primary;
      border-radius: 40px;
     /* border: 4px dashed $text;
      border-radius: 40px;*/

      &__title {
        @include font(1.4vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
      }

      &__text {
        @include font(1.4vw, 140%, $text, 500);
      }
    }
  }
}

.mobile {
  .section {
    &__desktop {
      display: none;

    }

    &__mobile {
      margin-bottom: 6.6vw;
    }

    margin-bottom: 9.2vw;

    .title {
      margin-bottom: 1.58vw;
    }

    &__text {
      @include font-20($height: 150%);
      margin-bottom: 3.17vw;
    }

    &__list {
      @extend .flex-col;
      gap: 4.1vw;

      &-item {
        padding: 4.1vw;
        border: 2px dashed $primary;
        border-radius: 20px;
        /*
        border: 2px dashed $text;
             border-radius: 20px;
        */

        &:not(:nth-child(1)):not(:nth-child(8)):not(:nth-child(11)) {
          display: none;
        }

        &__title {
          @include font-18($height: 120%);
          margin-bottom: 2.05vw;
        }

        &__text {
          @include font-16();
        }
      }
    }
  }

  .slider-container{
    width: 100%;
   /* height: calc(59.2308vw + 21vw);*/
    margin-top: 4.1vw;
    .slider-panel{
      width: inherit;
      /*height: 59.2308vw;*/
      margin: 0 2px;
        &-item {
          border: 4px dashed $primary;
          border-radius: 4.6512vw;
          /*width: 83vw;*/
          width: 70vw;
          height: 45vw;
          padding: 3vw;
          background-color: #f1f1f1;
          &__title {
            @include font(4.6154vw, 140%, $text, 600);
            text-align: center;
            margin-bottom: 1.05vw;
          }

          &__text {
            @include font(4.1026vw, 140%, $text, 500);
          }

      }
    }
  }
}
</style>
