export const payments = {
    title: 'ГИБКИЕ ВАРИАНТЫ ОПЛАТЫ',
    section: [
        {
            title1: 'Оплата "по факту',
            title2: 'использования":',
            subtitle: 'Какие функции вы получаете?',
            items: [
                {
                    title: 'Личный кабинет гостя:',
                    text: 'Гость получает доступ к персонализированной информации о бронировании и услугах, доступ через QR-код.'
                },
                {
                    title: 'Заказ услуг:',
                    text: 'Возможность заказать уборку, еду и напитки, вызвать такси или отправить запрос на ресепшн с выбором времени и даты.'
                },
                {
                    title: 'Бронирование дополнительных услуг:',
                    text: 'Возможность заказать спа, забронировать сауну или любую другую дополнительную услугу отеля.'
                },
                {
                    title: 'Реклама услуг и партнеров:',
                    text: 'Баннеры на главном экране для продвижения ваших услуг или продуктов партнеров.'
                },
                {
                    title: 'Отзывы гостей:',
                    text: 'Возможность оставить отзыв после каждой услуги, хранение информации для анализа и связи с клиентом.'
                },
                {
                    title: 'Рабочий кабинет сотрудников:',
                    text: 'Все запросы гостей поступают в персонализированный кабинет с push-уведомлениями для контроля.'
                },
                {
                    title: 'Аналитика и контроль:',
                    text: 'Мониторинг выполнения запросов, анализ скорости работы персонала.'
                },
                {
                    title: 'База данных гостей:',
                    text: 'Отслеживайте количество гостей в месяц/неделю/день. Храните их данные и предпочтения для обеспечения наилучшего сервиса.'
                },
            ],
        },
        {
            subtitle: 'Дополнительные преимущества:',
            subtext: 'Мы не только наполняем приложение информацией о вашем отеле, но и обеспечиваем полную поддержку:',
            subtext2: 'С нами ваш персонал готов к работе, а все вопросы решаются оперативно!',
            items: [
                {
                    title: 'Обучение персонала:',
                    text: 'Проводим инструктаж для сотрудников, чтобы они уверенно пользовались приложением.'
                },
                {
                    title: 'Настройка:',
                    text: 'Помогаем с настройкой и созданием аккаунтов для команды.'
                },
                {
                    title: 'Ежедневная поддержка:',
                    text: 'Всегда на связи, быстро решаем вопросы и предоставляем консультации, независимо от выбранного пакета.'
                },
            ],
        },
        {
            subtitle: 'Стоимость',
            subtext: 'Вам не нужно платить, если ваши гости не используют приложение. Вы платите только за фактическое использование.',
            items: [
                {
                    title: 'До 100 клиентов:',
                    text: 'Например: если в отеле 80 клиентов за месяц воспользовались приложением, стоимость составит 40 грн за клиента.',
                    price: '$1,5 / 1 клиент'
                },
                {
                    title: 'От 100 до 250 клиентов:',
                    text: 'Например: если в отеле 170 клиентов за месяц воспользовались приложением, стоимость составит 30 грн за клиента.',
                    price: '$1 / 1 клиент'
                },
                {
                    title: 'Более 250 клиентов:',
                    text: 'Например: если в отеле 270 клиентов за месяц воспользовались приложением, стоимость составит 20 грн за клиента.',
                    price: '$0,75 / 1 клиент'
                },
                {
                    title: 'Более 500 клиентов:',
                    text: 'Например: если в отеле 550 клиентов за месяц воспользовались приложением, стоимость составит 10 грн за клиента.',
                    price: '$0,5 / 1 клиент'
                },
            ],
        },
        {
            subtitle: 'Дополнительный функционал:',
            subtext: 'Вы также можете расширить свое приложение дополнительными функциями (например, онлайн-чат, бронирование номеров и т. д.), которые будут оплачиваться отдельно (фиксированная цена в месяц). Такой вариант обсуждается индивидуально.',
        },
        {
            title: 'Фиксированная абонентская плата:',
            items: [
                {
                    title: 'Basic',
                    items: [
                        {
                            text: 'Базовый список функционала мобильного приложения для отеля',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Поддержка и обучение персонала',
                            bullet: 'checked'
                        },
                        {
                            text: 'Онлайн-бронирование: быстрый просмотр номеров, цен и услуг с возможностью бронирования в приложении.',
                            bullet: 'crossed'
                        },
                        {
                            text: 'Чат с персоналом: быстрая коммуникация для уточнений, персонализации сервиса и индивидуальных предложений.',
                            bullet: 'crossed'
                        },
                    ],
                    price: '$100',
                    period: 'в месяц',
                    button: 'Заказать'
                },
                {
                    title: 'Standard',
                    items: [
                        {
                            text: 'Базовый список функционала мобильного приложения для отеля',
                            bullet: 'checked-star'
                        },
                        {
                            text: 'Поддержка и обучение персонала',
                            bullet: 'checked'
                        },
                        {
                            text: 'Онлайн-бронирование: быстрый просмотр номеров, цен и услуг с возможностью бронирования в приложении.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Чат с персоналом: быстрая коммуникация для уточнений, персонализации сервиса и индивидуальных предложений.',
                            bullet: 'checked'
                        },
                    ],
                    price: '$125',
                    period: 'в месяц',
                    button: 'Заказать'
                },
                {
                    title: 'Pro',
                    items: [
                        {
                            text: 'Список функций мобильного приложения «Стандартный пакет отеля»',
                            bullet: 'checked'
                        },
                        {
                            text: 'Печать меню с QR-кодом: возможность размещения QR-кодов в ресторане, СПА или номерах для быстрого доступа к меню и оформления заказа через приложение.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Онлайн-платежи: гости могут оплачивать услуги и бронирования прямо в приложении.',
                            bullet: 'checked'
                        },
                        {
                            text: 'Разработка индивидуальной программы лояльности для гостей в приложении.',
                            bullet: 'checked'
                        },
                    ],
                    price: '$150',
                    period: 'в месяц',
                    button: 'Заказать'
                }
            ],
            note: 'Базовый список функционала мобильного приложения для отеля',
            subitems: [
                {
                    title: 'Личный кабинет гостя:',
                    text: ' гость получает доступ к персональной информации о бронировании и услугах, доступ через QR Code.'
                },
                {
                    title: 'Заказ услуг:',
                    text: 'возможность заказать уборку, еду и напитки, вызвать такси или отправить запрос на ресепшн с выбором времени и дат.'
                },
                {
                    title: 'Резервация дополнительных услуг:',
                    text: 'возможность заказать СПА, забронировать сауну или любую дополнительную услугу отеля.'
                },
                {
                    title: 'Реклама услуг и партнеров:',
                    text: 'баннеры на главном экране для продвижения ваших услуг или продуктов партнеров.'
                },
                {
                    title: 'Отзывы гостей:',
                    text: 'возможность оставить отзыв после каждой услуги, сохранение информации для анализа и связи с клиентом.'
                },
                {
                    title: 'Рабочий кабинет сотрудников:',
                    text: 'все запросы гостей поступают в персональный кабинет с пуш-уведомлениями для контроля.'
                },
                {
                    title: 'Аналитика и контроль:',
                    text: 'мониторинг выполнения запросов, анализ скорости работы персонала.'
                },
                {
                    title: 'База данных гостей:',
                    text: 'Отслеживайте кол-во гостей в месяц/неделю/день. Сохраняйте их данные и преференции для обеспечения наилучшего сервиса.'
                },
            ]
        }
    ],
}
