<template>
  <div class="panel">
    <panel-header />
    <div class="section">
      <div class="section__content" style="">
        <div class="section__content__left">
          <div class="content" style="">
            <div class="top-row panel-text__left-title__service__top">
              <span>Smart&nbsp;</span>
              <span>Hotel</span>
            </div>

            <div class="bottom-row panel-text__left-title__service">
              <img class="bottom-row panel-text__left-title__service__image" src="@/assets/images/shs_photos.png" alt="img" />
              <span class="bottom-row panel-text__left-title__service__text-service">&nbsp;Service</span>
            </div>
          </div>
        </div>
        <div class="section__content-right">
          <div class="section__content-right-icons" >
            <div class="section__content-right-icons__item telegram" @click="redirectToTg('SHS')"></div>
            <div class="section__content-right-icons__item viber" @click="redirectToViber"></div>
          </div>
          <p class="section__content-right-text" style="">{{ $t('about_app') }}</p>
          <base-button class="bottom left" :text="$t('buttons.free_presentation')"  @click="redirectToTg('free_presentation')" />
        </div>
      </div>

    </div>

    <div class=" slider-container">
      <content-slider >
        <div class="slider-panel">
          <div class="folder">
            <img class="first" src="@/assets/images/guests.png" alt="img" id="guests" />
            <link-button-notxt class="notext" text="" @click="redirectToTg('app_for_guests')"  corner/>
          </div>
          <base-button class="left outline" :text="$t('buttons.client_functional')" @click="redirectToTg('app_for_guests')" outline :filled="filled"/>
        </div>
        <div class="slider-panel">
          <div class="folder">
            <img class="second" src="@/assets/images/MacBook.png" alt="img" id="staff" />
            <link-button-notxt class="notext" text="" @click="redirectToTg('app_for_staff')"  corner/>
          </div>
          <base-button class="left outline" :text="$t('buttons.staff_functional')" @click="redirectToTg('app_for_staff')" outline :filled="filled" />
        </div>
      </content-slider>
    </div>

    <div class="section__app">
      <div class="section__app-right-icons" >
        <div class="section__app-right-icons__item telegram" @click="redirectToTg('SHS')"></div>
        <div class="section__app-right-icons__item viber" @click="redirectToViber"></div>
      </div>
      <p class="section__app-right-text" style="">{{ $t('about_app') }}</p>
<!--
      <base-button class="bottom left" :text="$t('buttons.free_presentation')"  @click="redirectToTg('free_presentation')" />
-->
    </div>

    <div class="panel__content">
      <div class="panel__left">
        <div class="folder">
          <img src="@/assets/images/iPhone.png" alt="img" class="section__left" id="guests" />
          <link-button-notxt class="notext" text="" @click="redirectToTg('app_for_guests')"  corner/>
        </div>
        <base-button class="left outline" :text="$t('buttons.client_functional')" @click="redirectToTg('app_for_guests')" outline :filled="filled"/>
      </div>

      <div class="panel__right">
        <div class="folder">
          <img src="@/assets/images/MacBook.png" alt="img" class="section__right" id="staff" />
          <link-button-notxt class="notext" text="" @click="redirectToTg('app_for_staff')"  corner/>
        </div>
        <base-button class="left outline" :text="$t('buttons.staff_functional')" @click="redirectToTg('app_for_staff')" outline :filled="filled" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";
import BaseButton from "@/components/Button/BaseButton.vue";
import LinkButtonNotxt from "@/components/Button/LinkButtonNotxt.vue";
import ContentSlider from "@/components/ContentSlider.vue";
const filled = false;
const { redirectToTg, redirectToViber } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";



.desktop {
  .panel {
    .section__app{
      display: none;
    }
    .folder{

      @extend .rounded;
      @extend .flex-row;
      justify-content: center;
      height: 100%;
      /* border:0;
       outline: 0;*/
      position: relative;
      /*display: flex  ;
      *//*width: inherit;*/
      padding:  0;
      background-color: #f1f1f1;
      overflow: hidden;
      margin-bottom: 1vw;

      img{
        width: 30vw;
        height: auto;
        padding: 1vw 0 0;
        position: relative;
        z-index: 10
      }

      .notext{
        position: absolute;
        right: -1px;
        bottom: -1px;
        align-self: end;
        vertical-align: bottom;
        justify-self: flex-end;
        z-index: 11;
      }
    }
  }
  .slider-container {
    display: none;
  }
  #guests{
    width: 36.1772vw;
    height: 22.8175vw;
  }
  #staff{
    width: 41.0053vw;
    height: 24.5370vw;
  }
  .section__content{
    display: flex;
    justify-content: space-evenly;
    width: 100% ;
    padding: 0; margin: 0;

    .content{
      align-content: flex-start; padding-right: 1vw;
    }
    &-right{
      @extend .flex-col;
      justify-content: center;
      width: 30vw;

      &-text{
        @include font-18($height:140% );
        margin-bottom: 1.4vw;
      }

      &-icons {
        @extend .flex-row, .pointer;
        /*display: flex;
        justify-content: flex-start;
        flex-direction: row;*/
        &__item {
          width: 4.6296vw;
          min-width: 4.6296vw;
          height: 3.7037vw;
          margin: 0 1.2vw 1.4vw 0;

          &.viber {
            @include svgBg($viber);
          }

          &.telegram {
            @include svgBg($telegram-black);
          }
        }
      }
    }
  }
  .panel{
    &__content {
      @extend .flex-row;
      justify-content: space-between;
      gap: 6vw;
      margin-top: 3.24vw;
    }
    &__left {
      flex-grow: 4;
    }
    &__right {
      /*width: 43vw;*/
      flex-grow: 6;
    }
    .left.outline{
      float: left;
      padding: 0 1em;
    }
  }
  .panel-text {

    &__left {
      max-width: 37vw;
      margin-left: calc(min(4vw, 30px));

      &-title {
        @include font(calc(min(4.76vw, 100px)), 120%, $text, 550);

        &__service {
          /* @include font(calc(min(6vw, 100px)), 120%, $text, 520);*/
          @extend .flex-row;
          vertical-align: bottom;
          justify-content: space-between;
          text-align: left;

          &__top{
            @include font(calc(min(6.8vw, 110px)), 100%, $text, 600);
            text-transform: uppercase;
          }
          &__text-service{
            align-self: center;
            @include font(calc(min(6.8vw, 110px)), 100%, $text, 600);
            text-transform: uppercase;
            @extend .flex-row;
            vertical-align: bottom;
            justify-content: space-between;
          }
          &__image{
            align-self: center;
            width: auto;
            height: calc(min(5.2910vw, 80px));
          }
        }
      }

      &-subtitle {
        margin: 1.58vw 0 7vw;
        @include font-24($height: 140%);
      }

      &-avatars {
        margin-right: 1.32vw;

        &__item {
          @include square(calc(min(5.45vw, 70px)));
          border: 7px solid $primary;

          &:not(:first-child) {
            margin-left: -2vw;
          }
        }
      }
    }
  }
}

.mobile {
  .section__content{
    display: flex;
    /*justify-content: space-evenly;*/
    justify-content: left;
    width: 100% ;
    padding: 0; margin: 0;
    @extend .flex-col;
    &-right{
      @extend .flex-col;
      justify-content: center;
      width: 100vw;

      &-text{
        @include font-18($height:140% );
        margin-bottom: 1.4vw;
      }

      &-icons {
        @extend .flex-row, .pointer;
        /*display: flex;
        justify-content: flex-start;
        flex-direction: row;*/
        &__item {
          width: 4.6296vw;
          min-width: 4.6296vw;
          height: 3.7037vw;
          margin: 0 1.2vw 1.4vw 0;

          &.viber {
            @include svgBg($viber);
          }

          &.telegram {
            @include svgBg($telegram-black);
          }
        }
      }
    }
    .content{
      /*width: 67vw;*/
      float: left;
      .panel-text {

        &__left {
          @extend .flex-col;


          &-title {
            /* @include font(calc(min(4.76vw, 100px)), 120%, $text, 550);*/

            &__service {
              @extend .flex-row;
              vertical-align: bottom;
              justify-content: space-between;

              &__top{
                display: flex;
                flex-direction: row;
                @include font(calc(min(9.2308vw, 36px)), 100%, $text, 600);
                text-transform: uppercase;
                justify-content: space-between!important;
              }
              &__text-service{
                align-self: center;
                @include font(calc(min(9.2308vw, 36px)), 100%, $text, 600);
                text-transform: uppercase;
                @extend .flex-row;
                vertical-align: bottom;
                justify-content: space-between;
              }
              &__image{
                align-self: center;
                width: auto;
                height: calc(min(8.7179vw, 34px));
              }
            }
          }

          &-subtitle {
            margin: 1.58vw 0 7vw;
            @include font-24($height: 140%);
          }

          &-avatars {
            margin-right: 1.32vw;

            &__item {
              @include square(calc(min(5.45vw, 70px)));
              border: 7px solid $primary;

              &:not(:first-child) {
                margin-left: -2vw;
              }
            }
          }
        }
      }


    }
    &-right{
      display: none;
    }
  }
  .section__app{
    margin-top: 10vw;
    &-right{
      @extend .flex-col;
      justify-content: center;
      width: 30vw;


      &-text{
        @include font-18($height:140% );
        margin-bottom: 1.4vw;
      }

      &-icons {
        @extend .flex-row, .pointer;
        &__item {
          width: 11.5385vw;
          min-width: 11.5385vw;
          height: 9.2308vw;
          margin: 1vw 1.2vw 4vw 0;

          &.viber {
            @include svgBg($viber);
          }

          &.telegram {
            @include svgBg($telegram-black);
          }
        }
      }
    }
  }
  .panel{
    padding: 4.1vw 0;
    &__content {
      display: none;
    }

    &__item {
      @extend .row-container;
      /*@include font-18($color: $border-grey, $align: center);*/
      justify-content: space-between;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      background-color: #FFFFFFCC;
      border-radius: 10px;

      &-name {
        flex: 1;
        @include font(18px, 20px, black, 600);
        word-break: break-all;
        text-wrap: balance;
      }

      &-price {
        width: min-content;
        margin: 0 1rem;
        @include font(16px, 20px, black, 600, center);
        text-transform: uppercase;
      }



      &-button {
        min-width: 3.75rem;
        height: 2.5rem;
        padding: 0 0.5rem;
        @include font(18px, 2.5rem, black, 600, center);
        background-color: $primary;
        border-radius: 5px;
      }
    }
  }
  .slider-container{
    width: 100%;
    height: calc(67.9487vw + 2vw + 8.2051vw);
    margin-top: 4.1vw;
    .slider-panel{
      width: inherit;
      height: inherit;
      margin: 0 2px;
      .folder{
        @extend .rounded;
        @extend .flex-row;
        justify-content: center;
        height: 67.9487vw;
        width: 100%;
        position: relative;
        padding:  0;
        background-color: #f1f1f1;
        overflow: hidden;
        margin-bottom: 1vw;
        img{
          padding: 1vw 0 0;
          position: relative;
          z-index: 10;
          &.first {
            width: auto;
            height: 57.7564vw;
          }
          &.second {
            width: 96.5283vw;
            height: 57.7564vw;
          }
        }

        .notext{
          position: absolute;
          right: -1px;
          bottom: -1px;
          align-self: end;
          vertical-align: bottom;
          justify-self: flex-end;
          z-index: 11;
        }
      }
      .outline{
        float: left;
        padding: 0 7.1795vw;
        margin-left: 2px;
      }
    }
  }

}
</style>
