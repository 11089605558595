<template>
  <div class="section">
    <p class="title">{{ $t('home.benefits_title') }}</p>
    <p class="section__subtitle">{{ $t('home.benefits_subtitle') }}</p>
    <div class="section__content">
      <div v-for="(item, key) in items" :key="key" class="section__content-item">
        <p class="section__content-item__title">{{ $t(`home.${ item.title }`) }}</p>
        <p class="section__content-item__text">{{ $t(`home.${ item.text }`) }}</p>
        <div class="section__content-item__list">
          <p v-for="(text, t_key) in item.list" :key="t_key" class="section__content-item__list-item">
            {{ $t(`home.${ text }`) }}
          </p>
        </div>
        <base-button :text="$t(`home.${item.button}`)" outline filled  @click="redirectToTg('benefits_button')"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
// import LinkButton from "@/components/Button/LinkButton.vue";
// import { useRouter } from "vue-router";
import BaseButton from "@/components/Button/BaseButton.vue";
import {useButton} from "@/composable/useButton";
const { redirectToTg/*, redirectToViber*/ } = useButton();

// const { push } = useRouter();

const items = ref([{
  title: 'exp_title',
  text: 'exp_text',
  list: ['exp_item_1', 'exp_item_2', 'exp_item_3', 'exp_item_4', 'exp_item_5'],
  button: 'exp_button',
  buttonLink: '/client',
}, {
  title: 'tool_title',
  text: 'tool_text',
  list: ['tool_item_1', 'tool_item_2', 'tool_item_3', 'tool_item_4', 'tool_item_5'],
  button: 'tool_button',
  buttonLink: '/hotel',
}])
</script>

<style scoped lang="scss">
.section {
  @extend .flex-col;

  &__content {
    height: 100%;

    &-item {
      @extend .flex-col;
      flex: 1;

      &__list {
        @extend .flex-col;
        position: relative;

        &:after {
          position: absolute;
          @include before(100%, 100%);
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, #FFFFFF 93.5%);

          margin: 0 0 3px;
        }

        &-item {
          @extend .flex-row;
        }
      }
    }
  }
}

.wrapper {
  position: relative;
  justify-self: flex-end;
  margin-top: auto;
}

:deep(.link-button__bg) {
  display: none;
}

.desktop .section {
  margin: 6.75vw 0;

  .title {
    margin-bottom: 1.58vw;
  }

  &__subtitle {
    @include font-20();
    margin-bottom: 2.58vw;
  }

  &__content {
    @extend .flex-row;
    gap: 6.8vw;

    &-item {
      @extend .flex-col;
      justify-content: space-between;
      &__title {
        @include font(calc(min(2.5vw, 40px)), 125%, $text, 600);
        margin-bottom: 1rem;
      }

      &__text {
        @include font-20();
        margin-bottom: 2.38vw;
      }

      &__list {
        gap: 2.38vw;
        margin-bottom: 2.3vw;

        &:after {
          bottom: -2.3vw;
        }

        &-item {
          @include font-20($height: 125%, $weight: 600);

          &:before {
            @include before(4vw, 4vw);
            @include icon($tick, 4vw);
            margin-right: 1.05vw;
          }
        }
      }
    }
  }
}

.mobile .section {
  margin: 7vw 0 14.1vw;

  .title {
    margin-bottom: 2.05vw;
  }

  &__subtitle {
    @include font-16($weight: 400);
    margin-bottom: 6.15vw;
  }

  &__content {
    @extend .flex-col;
    gap: 14.1vw;

    &-item {
      &__title {
        @include font-24($weight: 600, $height: 120%);
        margin-bottom: 4.1vw;
      }

      &__text {
        @include font-16($weight: 400);
        margin-bottom: 8vw;
      }

      &__list {
        gap: 7vw;
        margin-bottom: 6.15vw;

        &:after {
          bottom: -6.15vw;
        }

        &-item {
          @include font-18($height: 120%);

          &:before {
            @include before(9.23vw, 9.23vw);
            @include icon($tick--mobile, 9.23vw);
            margin-right: 2.05vw;
          }
        }
      }
    }
  }
}
</style>
