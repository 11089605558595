<script setup lang="ts">
import {useArray} from "@/composable/useArray";
import {useButton} from "@/composable/useButton";
import FixedFeeTypes from "@/components/Home/PaymentsInfo/FixedFeeTypes.vue";
import TextContentSlider from "@/components/TextContentSlider.vue";

const { array4, array3, array8 } = useArray()
const { redirectToTg/*, redirectToViber*/ } = useButton();

</script>

<template>

   <div class="section">
    <div class="section__spectitle">
      <span class="number">2</span>
      <div class="text">
        <span class="quote">{{ $t('home.payments.section.4.title') }}</span>
      </div>
    </div>

    <div class="section__list desktop">
      <fixed-fee-types/>
    </div>
     <div class="slider-container section__list mobile">
       <text-content-slider show_controls>
         <fixed-fee-types/>
       </text-content-slider>
     </div>

     <div class="section__note">
       <span class="starred"></span>
       <p class="section__text" id="section__text">{{ $t('home.payments.section.4.note') }}</p>
     </div>
    <ul class="section__list-text no-marker">
      <li v-for="n in array8" :key="n" class="section__list-text-item">
        <span class="section__list-text-item__title">{{ $t(`home.payments.section.4.subitems.${n}.title`) }}</span>&nbsp;
        <span class="section__list-text-item__text">{{ $t(`home.payments.section.4.subitems.${n}.text`) }}</span>
      </li>
    </ul>
  </div>

</template>

<style scoped lang="scss">
.section {
  @extend .flex-col;

}


.desktop .section {
  margin: 3vw 0 0;
  &__list.mobile {display: none;}
  &__note{
    @extend .flex-row;
    gap: 0.7vw;
    justify-content: start;
    vertical-align: top;
    margin-bottom: 0.5vw;
    .starred{
      width: 1.4vw;
      height: 1.4vw;
      background-image: url($starred-item);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  #section1__text{
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 1.58vw;
  }

  &__supertitle{
    @include font-36($weight: 500);
    text-transform: uppercase;
  }

  &__spectitle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    vertical-align: middle;
    gap: 0.8em;
    margin: 1em 0;
    .number {
      @include font-60($height: 100%,$weight: 600);
      height: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include font-28($height: 100%,$weight: 600);

      /*font-weight: bold;*/
      text-transform: uppercase;
      height: 100%;
    }
    .quote {
      display: block;
    }

  }
  &__subtitle{
    @include font-28($weight: 600, $height: 300%);
  }

  &__text {
    @include font-24($height: 150%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list {
    @extend .flex-row;
    margin: 1vw auto 2vw;
    flex-wrap: wrap;
    gap: 1.5vw;

  }
  &__list-text.no-marker{
    list-style-position: outside;
    list-style-type: none;
    padding-left: 0 !important;
    .section__list-text-item {
      padding: 0;
      margin: 0;
      &__title {
        @include font-24($weight: 600);
      }
      &__text {
        @include font-24($weight: 500);
      }
    }
  }
}

.mobile .section {
  margin-bottom: 4vw;
  &__note{
    @extend .flex-col;
    gap: 0;
    justify-content: start;
    vertical-align: top;
    margin-left: 1em;
    &>p{
      @include font(3.7209vw, $weight: 500, $height: 120%);
    }
    .starred{
      margin-left: -1em;
      width: 3.0232vw;
      height: 3.0232vw;
      background-image: url($starred-item);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__list.desktop {display: none;}
  &__list.mobile{
    width: 100%;
    @extend .flex-row;
    margin: 1vw auto 2vw;
    /*width: 100%;*/
    flex-wrap: wrap;
    gap: 1.5vw;
  }
  &__spectitle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    vertical-align: middle;
    gap: 0.8em;
    margin:  3vw 0 2vw;
    .number {
      @include font-70($height: 100%,$weight: 600);
      height: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include font-24($height: 100%,$weight: 500);

      /*font-weight: bold;*/
      text-transform: uppercase;
      height: 100%;
    }
    .quote {
      display: block;
    }

  }

  &__note{
    @extend .flex-row;
    gap: 0.7vw;
    justify-content: start;
    vertical-align: top;
    margin-bottom: 0.5vw;
    .starred{
      width: 2.5vw;
      height: 2.5vw;
      background-image: url($starred-item);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  #section1__text{
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 1.58vw;
  }

  &__list-text.no-marker{
    list-style-position: outside;
    list-style-type: none;
    padding-left: 0 !important;
    .section__list-text-item {
      padding: 0;
      margin: 0;
      &__title {
        @include font-18($weight: 600);
      }
      &__text {
        @include font-18($weight: 500);
      }
    }
  }
}
</style>