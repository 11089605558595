<script setup lang="ts">
</script>

<template>
  <div class="section">
    <p class="section__subtitle af">{{ $t('home.payments.section.3.subtitle') }}</p>
    <p class="section__text af">{{ $t('home.payments.section.3.subtext') }}</p>
  </div>
</template>

<style scoped lang="scss">
.section {
  @extend .flex-col;

}

.desktop .section {
  margin: 0;

  .title {
    margin-bottom: 1.58vw;
  }
  &__subtitle{
    @include font-28($weight: 600, $height: 300%);
  }

  &__text {
    @include font-24($height: 150%);
  }

}

.mobile .section {
  margin: 0;
  &__subtitle{
    @include font-24($weight: 600, $height: 300%);
  }

  &__text {
    @include font-18($height: 150%);
  }

}
</style>