<script setup lang="ts">
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import BaseButton from "@/components/Button/BaseButton.vue";
import {useArray} from "@/composable/useArray";
import TextContentSlider from "@/components/TextContentSlider.vue";
const { t } = useI18n();

const items = computed(() => {
  const rawItems = t('home.paiments.items', { returnObjects: true });
  console.log('rawItems', rawItems);
  const itms = Array.isArray(rawItems) ? rawItems : Object.values(rawItems);
  console.log('items',itms)
  return itms;
});

const {array8} = useArray()
</script>

<template>
  <div class="section">
    <p class="title">{{ $t('home.payments.title') }}</p>
     <div class="section__spectitle">
      <span class="number">1</span>
      <div class="text">
        <span class="quote">{{ $t('home.payments.section.0.title1') }}&nbsp;</span>
        <span class="quote">{{ $t('home.payments.section.0.title2') }}</span>
      </div>
    </div>

    <p class="section__subtitle">{{ $t('home.payments.section.0.subtitle') }}</p>

    <div class="section__list desktop">
      <div v-for="n in array8" :key="n" class="section__list-item">
        <p class="section__list-item__title">{{ $t(`home.payments.section.0.items.${n}.title`) }}</p>
        <p class="section__list-item__text">{{ $t(`home.payments.section.0.items.${n}.text`) }}</p>
      </div>
    </div>

    <div class="slider-container section__list mobile">
      <text-content-slider show_controls>
        <div v-for="n in array8" :key="n" class="slide">
          <div class="slider-panel">
            <div class="section__list-item">
              <p class="section__list-item__title">{{ $t(`home.payments.section.0.items.${n}.title`) }}</p>
              <p class="section__list-item__text">{{ $t(`home.payments.section.0.items.${n}.text`) }}</p>
            </div>

          </div>
        </div>
      </text-content-slider>
    </div>
  </div>


</template>

<style scoped lang="scss">
.section {
  @extend .flex-col;

  &__list {
    &-item {
      @extend .flex-col;
      background-color: $light-grey;
      box-sizing: border-box;
      &.third{
        width: calc((100% - 1.5vw * 3) / 3) !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: stretch;
      }
    }
    &-item__price{
      /*@extend .flex-col;*/
      @extend .section__list-item;
      justify-content: center;
      .price-shape{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        width: 15vw;/*226px;*/
        height: 6.64vw; /* 100px;*/
        margin: 1.5vw 0 0;
        background: transparent url($price-shape) center no-repeat;
        background-size: contain;

        font-weight: bold;
      }
    }
  }
  &__list-text {
    /*padding: 0.5em 0 0.5em 2.5em;*/
    margin: 0;
    list-style-position: outside;
    &.img-marker {
      list-style-type: none;
      padding-left: 0 !important;
    }
    &.no-marker {
      list-style-position: outside;
      list-style-type: none;
      padding-left: 0 !important;
    }
    &.img-marker   &-item{
        display: flex;
        align-items: center;
        margin-bottom: 2em!important;

        .bullet{
          width: 2.43vw;
          height: 2.43vw;
          display: inline-block;
          /*background: transparent url($crossed-item) center no-repeat;*/
          background-position: left bottom;
          background-size: contain;
          background-repeat: no-repeat;
          flex:2;
          justify-self: center;
        }
        .text {
          flex: 9;
          margin-right: 10%;
          @include font(1.2rem);

        }
        &.crossed {
          .bullet {
            background-image: url($crossed-item);
          }
          .text {
            color: $text-light-grey;
          }
        }

        &.checked .bullet {
          background-image: url($checked-item);
        }

        &.checked-star .bullet{
          height: 2.84vw;
          background-image: url($checked-starred-item);
        }
      }

    &-item {
      padding: 0;
      margin: 0;

    }
  }
  #section1__text{
    margin-bottom: 0;
  }
}

.desktop .section {
  margin: 5vw 0 0;
  .slider-container{
    display: none;
  }

  &__list-text {
    padding: 0.5em 0 0.5em 2.5em;

    &-item {
      &__title {
        @include font-24($weight: 600);
      }
      &__text {
        @include font-24($weight: 500);
      }
    }
  }


  .title {
    margin-bottom: 1.58vw;
  }

  &__supertitle{
    @include font-36($weight: 500);
    text-transform: uppercase;
  }

  &__spectitle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    vertical-align: middle;
    gap: 0.8em;
    margin:  2vw 0 1vw;
    .number {
      @include font-54($height: 100%,$weight: 600);
      height: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include font-28($height: 100%,$weight: 600);

      /*font-weight: bold;*/
      text-transform: uppercase;
      height: 100%;
    }
    .quote {
      display: block;
    }

  }
  &__subtitle{
    @include font-28($weight: 600, $height: 300%);
  }

  &__text {
    @include font-24($height: 150%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list {
    margin: 1vw auto 2vw;
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1.5vw;
     &-item {
      width: calc((100% - 1.5vw * 3) / 4);
      padding: 1.25vw;
      border: 2px solid $primary;
      border-radius: 20px;

       &.third .section__list-item__title-center{
           @include font(2.38vw, 140%, $text, 600,$align: center);
       }

      &__title {
        @include font(1.4vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
        &-center{
          @include font(1.4vw, 140%, $text, 600,$align: center);
          margin-bottom: 1.05vw;
        }
      }

      &__subtitle-center{
        @include font(1.19vw, 140%, $text, 500,$align: center);
        margin-bottom: 1.05vw;
      }


      &__text {
        @include font(1.4vw, 140%, $text, 500);
        &-center{
          @include font(1.4vw, 140%, $text, 500,$align: center);
          margin-bottom: 1.05vw;
        }
      }


    }

  }
}

.mobile .section {
  margin: 9.2vw 0 1vw;

  .title {
    margin-bottom: 1.58vw;
  }

  &__subtitle{
    @include font-16();
  }

  &__supertitle{
    @include font-20($weight: 500);
    text-transform: uppercase;
  }

  &__text {
    @include font-22($height: 150%);
    margin-bottom: 3.17vw;
  }

  &__list {
    &.desktop{
      display: none;
    }
    &-item {
      width: 71.1628vw;
      height: 40vw;
      margin-right: 10px;
      padding: 3vw;
      border: 4px solid $primary;
      border-radius: 4.6512vw;
      &__title {
        @include font(4.1860vw, 120%, $text, 600);
        margin-bottom: 1.05vw;
      }
      &__text {
        @include font(3.7209vw, 140%, $text, 500);
      }
    }
  }
  &__subtitle{
    @include font-22($height: 300%);
  }

  &__supertitle{
    @include font-20($weight: 500);
    text-transform: uppercase;
  }

  &__spectitle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    vertical-align: middle;
    gap: 0.8em;
    margin:  2vw 0 1vw;
    .number {
      @include font-70($height: 100%,$weight: 600);
      height: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include font-24($height: 100%,$weight: 500);

      /*font-weight: bold;*/
      text-transform: uppercase;
      height: 100%;
    }
    .quote {
      display: block;
    }

  }
}
</style>