<template>
  <div class='field' v-if="selectedLanguage !== 'ru'">
    <div class='select' ref='item' :class='selectClass' @click.stop='onClick'>
      <p v-if="selectedLanguage" class='select__value'>{{ selectedLabel }}</p>
      <div class='select__arrow' :class="{'select__arrow--turned': isActive}"></div>
    </div>
    <transition :name="`show-${direction}`">
      <div v-if='isActive' class='select__options' v-bind='optionsParams'>
        <div class='select__options-list'>
          <div v-for='(option, key) in languages' :key='key' class='select__options-item'
               @click='onChange(option.value)'>
            <p class='select__options-item__text'>{{ option.label }}</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useFormItem } from "@/composable/useFormItem";
import { OptionParams, SelectOption } from "@/types/Form";
import { useI18n } from "vue-i18n";
// const { locale } = useI18n();
import { useRoute } from "vue-router";

const i18n = useI18n();
const route = useRoute();
const { isActive, item, getFloatingPosition, setListeners, deleteListeners } = useFormItem();

const direction = ref('down');
const optionsParams = ref<OptionParams>({} as OptionParams);
const languages = ref<SelectOption[]>([
  { label: 'ENG', value: 'en' },
  { label: 'PLN', value: 'pl' },
  { label: 'УКР', value: 'ua' }
]);

const selectedLanguage = computed((): string => i18n.locale.value);

const selectedLabel = computed((): string => languages.value.find(item => item.value === selectedLanguage.value)?.label ?? '');

const selectClass = computed((): { [key: string]: boolean } => {
  return {
    'select--active': isActive.value
  };
});

const onClick = () => {
  optionsParams.value = getFloatingPosition(Math.min(languages.value.length, 6), 36);
  direction.value = optionsParams.value?.class ? optionsParams.value.class : direction.value;
  isActive.value = !isActive.value;
};

const onChange = (value: string) => {
  i18n.locale.value = value;
  route.query.lang = value;
  isActive.value = false;
};

onMounted(() => {
  setListeners();
});
onUnmounted(deleteListeners);
</script>

<style lang='scss' scoped>
.field {
  @extend .rounded;
  height: 5.03vw;
  background-color: transparent;
  box-sizing: border-box;

  .select {
    @extend .row-container, .pointer;
    gap: 0.25rem;
    height: 100%;
    padding: 0 1.45vw 0 1.72vw;

    &__arrow {
      @include icon($arrow, 2.25rem, 2rem);
      transition-duration: 0.2s;

      &--turned {
        transform: rotate(0.5turn);
      }
    }

    &__value {
      @include font-20();
    }

    &__options {
      @extend .flex-col, .pointer;
      position: fixed;
      height: max-content;
      margin-top: 8px;
      padding: 1rem 0;
      overflow: hidden;
      z-index: 1000;
      box-sizing: border-box;
      background-color: #FFFFFFCC;
      border-radius: 20px;

      &-list {
        @extend .flex-col, .custom-scroll;
        gap: 10px;
        max-height: 2.25rem * 6;
        overflow-y: auto;
        backdrop-filter: blur(2px);
      }

      &-item {
        @extend .row-container;
        height: 2.7vw;
        padding: 0 1.58vw;
        box-sizing: content-box;
        transition-duration: 0.1s;

        &__text {
          color: white;
          @include font-24($height: 145%);
        }
      }
    }
  }
}

.mobile .field {
  position: absolute;
  right: calc(16.15vw + 2.56vw);
  height: 14.75vw;
  padding: 0 1.2vw;
  border-radius: 10px;
  background-color: transparent;

  .select__value {
    @include font-18();
  }

  .select__arrow {
    min-width: 6.15vw;
    max-width: 6.15vw;
    background-size: 6.15vw;
  }

  .select__options-list {
    max-height: max-content;
    height: max-content;
  }

  .select__options-item {
    height: 8vw;
    padding: 0 3vw;

    &__text {
      @include font-18();
    }
  }
}

.show {
  &-up, &-down {
    &-leave, &-enter {
      &-from, &-to {
        transition: all 0.5s;
      }
    }
  }

  &-up, &-down {
    &-leave-from, &-enter-to {
      clip-path: inset(0 0 0 0);
    }
  }
}

.show-down-enter-from, .show-down-leave-to {
  clip-path: inset(0 0 100% 0);
}

.show-up-leave-to, .show-up-enter-from {
  clip-path: inset(100% 0 0 0);
}
</style>
