<script setup lang="ts">

import {useArray} from "@/composable/useArray";
const { array3 } = useArray()
</script>

<template>
  <div class="section section1">
    <p class="section__subtitle">{{ $t('home.payments.section.1.subtitle') }}</p>
    <p class="section__text" id="section1__text">{{ $t('home.payments.section.1.subtext') }}</p>
    <ul class="section__list-text">
      <li v-for="n in array3" :key="n" class="section__list-text-item">
        <span class="section__list-text-item__title">{{ $t(`home.payments.section.1.items.${n}.title`) }}</span>&nbsp;
        <span class="section__list-text-item__text">{{ $t(`home.payments.section.1.items.${n}.text`) }}</span>
      </li>
    </ul>
    <p class="section__text">{{ $t('home.payments.section.1.subtext2') }}</p>
  </div>
</template>
<style scoped lang="scss">
.section {
  @extend .flex-col;

  &__list {
    margin: 1vw auto 2vw;
    &-item {
      @extend .flex-col;
      background-color: $light-grey;
      box-sizing: border-box;
      &.third{
        width: calc((100% - 1.5vw * 3) / 3) !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: stretch;
      }
    }
    &-item__price{
      /*@extend .flex-col;*/
      @extend .section__list-item;
      justify-content: center;
      .price-shape{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        width: 15vw;/*226px;*/
        height: 6.64vw; /* 100px;*/
        margin: 1.5vw 0 0;
        background: transparent url($price-shape) center no-repeat;
        background-size: contain;

        font-weight: bold;
      }
    }
  }

}

.desktop .section {
  margin: 0;
  .title {
    margin-bottom: 1.58vw;
  }
  &__subtitle{
    @include font-28($weight: 600, $height: 300%);
  }

  &__text {
    @include font-24($height: 150%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list-text {
    padding: 0.5em 0 0.5em 2.5em;
    margin: 0;
    list-style-position: outside;
    &.img-marker {
      list-style-type: none;
      padding-left: 0 !important;
    }
    &.no-marker {
      list-style-position: outside;
      list-style-type: none;
      padding-left: 0 !important;
    }
    &.img-marker   &-item{
      display: flex;
      align-items: center;
      margin-bottom: 2em!important;

      .bullet{
        width: 2.43vw;
        height: 2.43vw;
        display: inline-block;
        /*background: transparent url($crossed-item) center no-repeat;*/
        background-position: left bottom;
        background-size: contain;
        background-repeat: no-repeat;
        flex:2;
        justify-self: center;
      }
      .text {
        flex: 9;
        margin-right: 10%;
        @include font(1.2rem);

      }
      &.crossed {
        .bullet {
          background-image: url($crossed-item);
        }
        .text {
          color: $text-light-grey;
        }
      }

      &.checked .bullet {
        background-image: url($checked-item);
      }

      &.checked-star .bullet{
        height: 2.84vw;
        background-image: url($checked-starred-item);
      }
    }

    &-item {
      &__title {
        @include font-24($weight: 600);
      }
      &__text {
        @include font-24($weight: 500);
      }
      padding: 0;
      margin: 0;

    }
  }

  #section1__text{
    margin-bottom: 0;
  }
}

.mobile .section {
  margin:  0 0 1vw;
  .title {
    margin-bottom: 1.58vw;
  }
  &__subtitle{
    @include font-24($weight: 600, $height: 300%);
  }

  &__text {
    @include font-18($height: 140%);
    /*margin-bottom: 3.17vw;*/
  }

  &__list-text {
    padding: 0.5em 0 0.5em 1.8em;
    margin: 0;
    list-style-position: outside;
    &.img-marker {
      list-style-type: none;
      padding-left: 0 !important;
    }
    &.no-marker {
      list-style-position: outside;
      list-style-type: none;
      padding-left: 0 !important;
    }
    &-item {
      &__title {
        @include font-18($weight: 600);
      }
      &__text {
        @include font-18($weight: 500);
      }
      padding: 0;
      margin: 0;

    }
  }

  #section1__text{
    margin-bottom: 0;
  }

}
</style>