import { payments } from "@/locales/pl/payments";
export const pl = {
  about_app: 'Innowacyjna aplikacja stworzona w celu zapewnienia gościom wyjątkowego komfortu i wygody podczas pobytu, jednocześnie optymalizując pracę personelu i zwiększając rentowność hotelu.',
  improve_your_business: 'POPRAW SWÓJ BIZNES Z NAMI',
  free_presentation: 'DARMOWA PREZENTACJA NA ŻYWO',
  copied: 'Skopiowany',
  successful_data_send: 'Twój e-mail został pomyślnie wysłany',
  error_data_send: 'Nie można wysłać Twojego e-maila',

  tabs: {
    about: 'O Nas',
    client: 'Dla Klientów',
    hotel: 'Dla Hoteli',
    contacts: 'Kontakty',
    prices: 'Cena',
  },

  buttons: {
    free_month: 'Darmowy Miesiąc',
    our_services: 'Nasze Usługi',
    send: 'Wyślij',
    coworking_details: 'Szczegóły współpracy',
    order_presentation: 'Zamów prezentację',
    free_presentation: 'Darmowa prezentacja',
    client_functional: 'Funkcjonalność dla gości',
    staff_functional: 'Funkcjonalność dla personelu',
  },

  home: {
    payments,
    title_1: 'Smart',
    title_2: 'Hotel',
    title_3: 'Service',
    subtitle: 'Innowacyjna aplikacja stworzona w celu zapewnienia gościom wyjątkowego komfortu i wygody podczas pobytu, a jednocześnie optymalizacji pracy personelu oraz zwiększenia rentowności hotelu.',
    about_title: 'O NAS',
    about_text: 'Przedstawiamy Smart Hotel Service, nowoczesną aplikację stworzoną, aby uprościć i ulepszyć hotelowe doświadczenia gości, jednocześnie zwiększając zyski hoteli.<br/>Z Smart Hotel Service goście mogą zamawiać różne usługi hotelowe, a Ty łatwo wyniesiesz swój biznes na wyższy poziom.<br/>Wdrożenie naszego systemu pozwala hotelowi nie tylko podnieść poziom obsługi, ale także stać się liderem w branży hotelarskiej, zapewniając wyjątkowe doświadczenia każdemu gościowi.',
    form_title: 'INDYWIDUALNA PREZENTACJA ONLINE SPECJALNIE DLA CIEBIE',
    form_hint: 'Wypełnij i wyślij swoje dane, a oddzwonimy do Ciebie, aby umówić się na DARMOWĄ prezentację!',
    form_name: 'Imię i Nazwisko',
    form_phone: 'Numer Telefonu',
    benefits_title: 'WSZYSTKO W JEDNYM SYSTEMIE',
    benefits_subtitle: 'W dzisiejszych czasach hotelarstwo jest jednym z kluczowych czynników decydujących o sukcesie hotelu. Aby zapewnić wysoki poziom obsługi i zadowolenia klientów, oferujemy innowacyjny system zarządzania, który poprawia wszystkie aspekty działalności hotelu.',
    exp_title: 'SPERSONALIZOWANE DOŚWIADCZENIE GOŚCI',
    exp_text: 'Nasz system zapewnia gościom spersonalizowaną aplikację, która znacznie upraszcza ich interakcję z hotelem. Dzięki aplikacji goście mogą:',
    exp_item_1: 'Zamawiać jedzenie bezpośrednio do pokoju, wybierając z bogatego menu restauracji.',
    exp_item_2: 'Zamawiać usługi sprzątania, wybierając dogodny czas na usługę.',
    exp_item_3: 'Zamawiać taksówki i inne usługi transportowe bezpośrednio przez aplikację.',
    exp_item_4: 'Kontaktować się z recepcją w celu szybkiego rozwiązania problemów lub spełnienia próśb.',
    exp_item_5: 'Rezerwować zabiegi spa i inne dodatkowe usługi hotelowe.',
    exp_button: 'Wszystkie Korzyści Dla Gości',
    tool_title: 'EFEKTYWNE NARZĘDZIE DLA PERSONELU',
    tool_text: 'Każdy pracownik hotelu ma dostęp do spersonalizowanego miejsca pracy, które optymalizuje wykonywanie zadań:',
    tool_item_1: 'Otrzymywanie na bieżąco próśb od gości z możliwością natychmiastowej odpowiedzi.',
    tool_item_2: 'Monitorowanie realizacji próśb z możliwością śledzenia czasu i jakości usługi.',
    tool_item_3: 'Harmonogramy rezerwacji dodatkowych usług, które zapewniają nieprzerwaną i skoordynowaną obsługę.',
    tool_item_4: 'Analiza efektywności personelu na podstawie opinii gości i czasu realizacji próśb.',
    tool_item_5: 'Udzielanie opinii gościom na podstawie ich recenzji, co pomaga budować zaufanie i zwiększać zadowolenie klientów.',
    tool_button: 'Wszystkie Korzyści Dla Hoteli',
    offer_title: 'NOWY POZIOM KOMFORTU DLA GOŚCI',
    offer_text: 'Z chęcią odpowiemy na wszystkie dodatkowe pytania i wesprzemy Cię na każdym etapie wdrożenia systemu, pomagając Twojemu biznesowi osiągnąć nowy poziom sukcesu.',
    offer_button: 'Chcę Poprawić Mój Biznes!',
    reviews_title: 'PRAWDZIWE OPINIE OD PRAWDZIWYCH LUDZI',
    review_1_name: 'Anna, 48 lat',
    review_1_type: 'Menedżer Hotelu',
    review_1_text: 'Początkowo wątpiłam w zasadność wdrożenia tego systemu, ale wyniki przerosły wszelkie oczekiwania. Program znacznie uprościł nasze procesy i zwiększył efektywność pracy. To naprawdę dobra inwestycja dla naszego hotelu!',
    review_2_name: 'Dmytro, 44 lata',
    review_2_type: 'Właściciel Hotelu',
    review_2_text: 'Dzięki wdrożeniu tego systemu każdy gość jest zadowolony z naszej obsługi. Otrzymujemy wiele komplementów za bycie na bieżąco, a zauważyliśmy znaczny wzrost zysków. Ten system naprawdę poprawił nasze procesy i podniósł ogólny poziom obsługi.',
    review_3_name: 'Maria, 32 lata',
    review_3_type: 'Gość Hotelu',
    review_3_text: 'Byłam mile zaskoczona, gdy po zeskanowaniu kodu uzyskałam dostęp do aplikacji hotelowej. Wszystko było niezwykle wygodne: mogłam zamówić jedzenie, zorganizować sprzątanie i skorzystać z innych usług bez wychodzenia z pokoju. To naprawdę fajna usługa!',
    review_4_name: 'Nastya, 29 lat',
    review_4_type: 'Administrator Hotelu',
    review_4_text: 'Początkowo byłam sceptyczna, ale okazało się, że jest to niezwykle przydatne. System znacznie ułatwił naszą pracę, automatyzując wiele rutynowych zadań i zapewniając wygodny dostęp do informacji. To prawdziwy przełom dla naszego hotelu!',
    review_5_name: 'Ihor, 52 lata',
    review_5_type: 'Gość Hotelu',
    review_5_text: 'Mogłem przedłużyć pobyt bez wychodzenia z pokoju i byłem mile zaskoczony, jak proste i wygodne było to zrobić przez aplikację. Nawet jeśli nie jestem bardzo biegły w technologii, ten system okazał się bardzo intuicyjny i łatwy w obsłudze.',
    points_title: 'CO ZYSKUJESZ DZIĘKI AKTUALIZACJI SWOJEGO BIZNESU?',
    points_text: 'Wdrożenie naszej aplikacji przynosi liczne korzyści, które przyczyniają się do poprawy jakości obsługi i efektywności Twojego biznesu. Oto główne zalety naszego systemu:',
    points_item_1_title: 'Nowoczesne Technologie:',
    points_item_1_text: 'Wdrożenie nowoczesnych technologii optymalizuje działanie i zwiększa ogólną efektywność hotelu.',
    points_item_2_title: 'Komfort gości:',
    points_item_2_text: 'Dzięki wygodnej aplikacji goście zamawiają i otrzymują usługi bez wychodzenia z pokoju. Nie trzeba już dzwonić ani iść do recepcji.',
    points_item_3_title: 'Spersonalizowana Obsługa:',
    points_item_3_text: 'Dzięki indywidualnej aplikacji goście mają wygodny dostęp do wszystkich usług hotelowych.',
    points_item_4_title: 'Pozytywne Opinie:',
    points_item_4_text: 'Wysoki poziom obsługi prowadzi do wzrostu pozytywnych opinii i lojalności gości.',
    points_item_5_title: 'Odpowiednie Skupienie:',
    points_item_5_text: 'Personel może skoncentrować się na realizacji ważniejszych zadań, ponieważ powtarzalne obowiązki administracyjne są zautomatyzowane.',
    points_item_6_title: 'Kontrola Jakości Hotelu:',
    points_item_6_text: 'Administratorzy mogą monitorować realizację próśb i kontrolować jakość usług.',
    points_item_7_title: 'Reklama i Sprzedaż:',
    points_item_7_text: 'Możliwość reklamowania i sprzedaży swoich usług oraz usług partnerów przez aplikację pozwala hotelowi przyciągać więcej klientów i zwiększać wolumeny sprzedaży.',
    points_item_8_title: 'Wzrost Zysków:',
    points_item_8_text: 'Nasz system zwiększa przychody hotelu, przyciągając więcej klientów i zwiększając wolumeny sprzedaży. To bezpośrednio przyczynia się do ogólnego wzrostu rentowności hotelu.',
    points_item_9_title: 'Całodobowe Wsparcie:',
    points_item_9_text: 'Aplikacja zapewnia całodobowe wsparcie online zarówno dla gości, jak i personelu hotelu, umożliwiając szybkie rozwiązanie wszelkich problemów.',
    points_item_10_title: 'Szkolenie Pracowników:',
    points_item_10_text: 'Oferujemy możliwość przeszkolenia Twojego personelu, aby skutecznie korzystał ze wszystkich funkcji systemu.',
    points_item_11_title: 'Zadowoleni klienci:',
    points_item_11_text: 'Poprawa poziomu zadowolenia klientów dzięki łatwemu dostępowi do usług i szybkiemu reagowaniu na ich potrzeby.',
    points_item_12_title: 'Elastyczność i Różnorodność Funkcji:',
    points_item_12_text: 'System „konstruktor” – wybierasz dokładnie ten zestaw funkcji, który najlepiej odpowiada Twoim potrzebom.',
  },

  client: {
    main_title: 'NOWY POZIOM KOMFORTU DLA GOŚCI',
    main_text: 'Nasza aplikacja sprawia, że pobyt gości w hotelu jest maksymalnie komfortowy, umożliwiając zamawianie wszelkich usług bezpośrednio z pokoju. Wszystkie kwestie są szybko rozwiązywane dzięki całodobowej pomocy online, a dostęp do wszystkich niezbędnych informacji o hotelu jest zawsze pod ręką. Zapewnia to beztroski wypoczynek i wysoki poziom obsługi.',
    steps_title: 'TRZY PROSTE KROKI',
    steps_item_1: 'Utwórz spersonalizowany link do aplikacji swojego hotelu, wypełniając prosty formularz w panelu pracowniczym',
    steps_item_2: 'Pozwól gościowi zeskanować kod QR, który pojawił się na ekranie, lub wyślij link na e-mail',
    steps_item_3: 'Realizuj zapytania gości, zbieraj pozytywne opinie i ciesz się pracą.',
    features_title: 'NOWE MOŻLIWOŚCI DLA GOŚCI',
    features_item_1_title: 'Zamawianie usług: ',
    features_item_1_text: 'Goście mogą łatwo zamówić sprzątanie, dodatkowe poduszki, taksówki, jedzenie i napoje, a także zarezerwować zabiegi spa, saunę lub masaż bezpośrednio z pokoju.',
    features_item_2_title: 'Zapytania do recepcji:',
    features_item_2_text: 'Wszystkie zapytania lub pytania można wysłać bezpośrednio przez aplikację, co ułatwia komunikację z hotelem.',
    features_item_3_title: 'Opinie:',
    features_item_3_text: 'Goście mogą zostawić opinie na każde zapytanie, co pomaga hotelowi poprawiać jakość obsługi.',
    features_item_4_title: 'Powiadomienia:',
    features_item_4_text: 'Goście otrzymują powiadomienia o statusie swoich zapytań, w tym o rozpoczęciu, zakończeniu lub anulowaniu realizacji.',
    features_item_5_title: 'Automatyczne blokowanie:',
    features_item_5_text: 'Po wyjeździe dostęp do usług hotelowych, takich jak zamawianie jedzenia czy rezerwacje, jest automatycznie blokowany, ale informacje i możliwość rezerwacji pokoi pozostają dostępne.',
    features_item_6_title: 'Informacje o hotelu:',
    features_item_6_text: 'Aplikacja zapewnia dostęp do informacji o hotelu, restauracji i rozrywce, co umożliwia gościom wygodne zapoznanie się ze wszystkimi ofertami.',
    features_item_7_title: 'Dodatkowe funkcje:',
    features_item_7_text: 'Dla wygody gości i hotelu dostępne są płatności online, SMS-owe powiadomienia o nowinkach i zniżkach. Jeśli potrzebna jest specjalna funkcja, możemy ją zrealizować po omówieniu szczegółów.',
    features_item_8_title: 'Funkcja bezpośredniego kontaktu:',
    features_item_8_text: 'Aplikacja umożliwia bezpośredni kontakt z administratorem lub pracownikiem recepcji. Goście mogą w dowolnym momencie wysyłać wiadomości bezpośrednio do pracownika przez aplikację, co zapewnia szybkie rozwiązywanie problemów i zwiększa poziom obsługi.',
    features_item_9_title: 'Historia zapytań:',
    features_item_9_text: 'W aplikacji przechowywana jest historia wszystkich zapytań, rezerwacji i rezerwacji, co umożliwia łatwe przeglądanie wcześniejszych działań.',
    features_item_10_title: 'ROZPOCZNIJ JUŻ TERAZ',
    features_item_10_text: 'Kliknij tutaj, a my przeprowadzimy Cię przez indywidualną prezentację aplikacji',
    features_item_11_title: 'Zamawianie dodatkowych usług:',
    features_item_11_text: 'SPA lub jazda na nartach - goście mogą uzyskać informacje i zamówić każdą usługę oferowaną przez hotel bezpośrednio z telefonu.',
  },

  hotel: {
    main_title: 'NOWY POZIOM BIZNESU',
    main_text: 'Wdrożenie naszej aplikacji znacznie zwiększy efektywność Twojego hotelu, automatyzując rutynowe procesy i ułatwiając zarządzanie. Wygoda dla gości, szybkość przetwarzania zapytań oraz integracja nowoczesnych technologii podniosą poziom obsługi, co pozytywnie wpłynie na reputację i rentowność hotelu.',
    features_title: 'EFEKTYWNE NARZĘDZIE DLA PERSONELU I WŁAŚCICIELI HOTELS',
    features_text: 'Każda z tych funkcji została zaprojektowana w celu zwiększenia efektywności zarządzania hotelem, poprawy jakości obsługi gości oraz zapewnienia elastyczności w dostosowywaniu systemu do potrzeb Twojego biznesu.',
    features_item_1_title: 'Informacje o Hotelu i Usługach',
    features_item_1_text: 'Aplikacja zawiera wszystkie niezbędne informacje o hotelu, w tym zdjęcia, godziny otwarcia, menu restauracji, cenniki i opisy dodatkowych usług. Goście będą mieli dostęp do tych informacji nawet po wymeldowaniu.',
    features_item_2_title: 'Zarządzanie zapytaniami',
    features_item_2_text: 'Personel otrzymuje zapytania przez aplikację i ma możliwość zobaczenia, jak długo zapytanie jest w systemie oraz kto je utworzył. Pracownicy mogą akceptować, anulować lub kończyć zapytania. Po wykonaniu, zapytanie automatycznie zapisuje się w historii, gdzie administrator zawsze widzi, kto realizował zapytanie i jak długo to trwało. To zapewnia przejrzystość i kontrolę nad jakością obsługi.',
    features_item_3_title: 'Harmonogram Rezerwacji Dodatkowych Usług',
    features_item_3_text: 'Goście mogą rezerwować dodatkowe usługi, takie jak masaż lub sauna, przez aplikację, a personel otrzymuje te zapytania do przetworzenia. Po potwierdzeniu, zapytanie pojawia się w harmonogramie konkretnej usługi. Dodatkowo istnieje możliwość dostosowania rezerwacji usług poprzez bezpośredni kontakt (czat) z pracownikiem. Możesz również blokować wszystkie wolne terminy na sprzątanie lub naprawy.',
    features_item_4_title: 'Harmonogram Rezerwacji',
    features_item_4_text: 'Aplikacja dla personelu oferuje wygodny harmonogram rezerwacji, w którym pracownicy mogą potwierdzać lub anulować rezerwacje oraz kontaktować się z klientami. W harmonogramie można ręcznie dodać rezerwację, jeśli goście dzwonią bezpośrednio, co pozwala na przechowywanie wszystkich informacji w jednym miejscu.',
    features_item_5_title: 'Zarządzanie Opiniami i Informacjami o Gościach',
    features_item_5_text: 'Wszystkie opinie pozostawione przez gości są przechowywane w aplikacji wraz z informacjami kontaktowymi gości. To umożliwia pracownikom udzielenie odpowiedzi klientom lub szybkie rozwiązanie problemów, co podnosi jakość obsługi.',
    features_item_6_title: 'Baza Pracowników',
    features_item_6_text: 'System umożliwia tworzenie kont dla nieograniczonej liczby pracowników, z przypisaniem każdego do konkretnego działu. To pozwala pracownikom otrzymywać tylko te zapytania, które dotyczą ich działu, co zwiększa efektywność pracy.',
    features_item_7_title: 'Reklama Usług Hotelowych i Partnerów',
    features_item_7_text: 'Możesz dodawać nieograniczoną liczbę banerów reklamowych do aplikacji, które będą prowadzić do strony z informacjami o reklamowanej usłudze Twojego hotelu lub Twoich partnerów. To wpłynie na wzrost sprzedaży i zysków. Reklama partnerów jest płatna osobno w atrakcyjnej cenie.',
    features_item_8_title: 'Wersja Mobilna i Komputerowa',
    features_item_8_text: 'Aplikacja dla personelu została stworzona w dwóch wersjach — mobilnej i stacjonarnej, co zapewnia wygodę pracy w dowolnym miejscu i na dowolnym urządzeniu.',
    features_item_9_title: 'Całodobowe Wsparcie',
    features_item_9_text: 'Nasz dział wsparcia działa przez całą dobę, aby pomóc rozwiązać wszelkie problemy lub kwestie techniczne. Zawsze jesteśmy w kontakcie, aby zapewnić nieprzerwaną pracę Twojego hotelu.',
    features_item_10_title: 'Dodatkowe Funkcje',
    features_item_10_text: 'Program zawiera dodatkowe funkcje, takie jak oprogramowanie do drukowania rachunków-zamówień w kuchni, wysyłanie SMS-ów z promocjami i ofertami oraz drukowanie kodów QR z menu lub innymi informacjami.',
    qr_title: 'KODY QR DLA KAŻDEGO POMIESZCZENIA',
    qr_text: 'Nasz system umożliwia tworzenie i drukowanie specjalnych kodów QR dla menu restauracji, które umieszcza się na stolikach. To zapewnia gościom wygodny sposób zamawiania jedzenia i napojów bezpośrednio ze swoich smartfonów. Ta funkcja znacznie poprawia komfort pobytu w restauracji, zwiększając wygodę i efektywność zarówno dla gości, jak i dla personelu. Wprowadzenie kodów QR upraszcza proces obsługi i zmniejsza obciążenie personelu, umożliwiając skupienie się na jakości obsługi i zaspokajaniu potrzeb klientów.',
    qr_item_1_title: 'Umieszczenie kodów QR:',
    qr_item_1_text: 'Każdy stolik w restauracji jest wyposażony w unikalny kod QR, który prowadzi do cyfrowego menu.',
    qr_item_2_title: 'Skanowanie i zamawianie:',
    qr_item_2_text: 'Gość hotelu skanuje kod QR za pomocą swojego smartfona i uzyskuje dostęp do pełnego menu restauracji. Po wyborze dań i napojów, gość może złożyć zamówienie przez aplikację.',
    qr_item_3_title: 'Przetwarzanie zamówienia:',
    qr_item_3_text: 'Zamówienie automatycznie trafia do panelu pracownika, gdzie widzi:<br/><br/>- Numer stolika, z którego złożono zamówienie.<br/> - Szczegóły zamówionych dań i napojów.<br/> - Wybraną przez gościa metodę płatności (gotówką, kartą lub przez aplikację).',
    qr_item_4_title: 'Wygoda dla gości:',
    qr_item_4_text: 'Goście mogą łatwo przeglądać menu, wybierać dania i zaznaczać swoje preferencje (np. alergie lub inne potrzeby dietetyczne) bez konieczności czekania na kelnera. To zwiększa szybkość obsługi i zapewnia doskonały serwis.',
    qr_item_5_title: 'Korzyści dla personelu:',
    qr_item_5_text: 'Pracownicy restauracji otrzymują zamówienia w formie elektronicznej, co minimalizuje ryzyko błędów przy przekazywaniu informacji. Mogą szybko przygotować zamówienie i przekazać je do kuchni do realizacji.',
    qr_item_6_title: 'Płatność:',
    qr_item_6_text: 'Goście mogą wybrać dogodny dla siebie sposób płatności, co sprawia, że proces finalizacji zamówienia jest prosty i zrozumiały.',
    qr_hint: '*Ta funkcja jest dostępna dodatkowo i płatna osobno',
  },

  contacts: {
    footer_link_1: 'KONTAKT',
    footer_link_2: 'POLITYKA PRYWATNOŚCI',
    footer_link_3: 'WARUNKI KORZYSTANIA',
    main_title_header: 'KONTAKT',
    main_title_content: 'Skontaktuj się z nami w dowolny dogodny sposób, a chętnie odpowiemy na wszystkie dodatkowe pytania, przeprowadzimy prezentację online oraz udzielimy wszelkich szczegółowych informacji dotyczących współpracy.',
    contact_icon_telegram: 'TELEGRAM',
    contact_icon_email: 'E-MAIL',
    contact_icon_phone: 'TELEFON',
  },

  privacy: {
    header: 'POLITYKA PRYWATNOŚCI',
    text_0: 'Smart Hotel Service (dalej "Firma", "my", "nas") szanuje Twoją prywatność i zobowiązuje się do ochrony Twoich danych osobowych. Niniejsza Polityka Prywatności wyjaśnia, w jaki sposób zbieramy, wykorzystujemy, ujawniamy i chronimy Twoje informacje podczas korzystania z naszej mobilnej aplikacji hotelowej (dalej "Aplikacja").',
    title_1: '1. Informacje, które zbieramy',
    text_1: 'Możemy zbierać następujące rodzaje informacji:<br/><br/>- **Dane osobowe:** Podczas rejestracji lub korzystania z Aplikacji możemy zbierać Twoje imię, adres e-mail, numer telefonu i inne dane kontaktowe.<br/>- **Dane dotyczące użytkowania:** Automatycznie zbieramy informacje o tym, jak korzystasz z naszej Aplikacji, w tym Twój adres IP, typ urządzenia, system operacyjny, typ przeglądarki i inne dane dotyczące użytkowania.<br/>- **Dane finansowe:** Jeśli dokonasz płatności przez naszą Aplikację, możemy zbierać informacje o płatności, takie jak numer karty kredytowej i adres rozliczeniowy.',
    title_2: '2. Jak wykorzystujemy Twoje informacje',
    text_2: 'Wykorzystujemy Twoje informacje w celu:<br/><br/>- Świadczenia i doskonalenia naszej Aplikacji i usług.<br/>- Przetwarzania transakcji i zarządzania Twoimi zamówieniami.<br/>- Świadczenia wsparcia klienta i odpowiedzi na Twoje zapytania.<br/>- Dostarczania Ci aktualizacji, wiadomości i innych informacji o naszych usługach, jeśli wyraziłeś zgodę na otrzymywanie takich komunikatów.<br/>- Analizy użytkowania Aplikacji w celu ulepszenia naszych produktów i usług.',
    title_3: '3. Jak ujawniamy Twoje informacje',
    text_3: 'Nie ujawniamy Twoich danych osobowych osobom trzecim, z wyjątkiem następujących przypadków:<br/><br/>- Dostawcy usług: Możemy udostępniać Twoje informacje naszym zaufanym dostawcom usług, którzy pomagają nam w świadczeniu i wspieraniu Aplikacji.<br/>- Wymogi prawne: Możemy ujawnić Twoje informacje, jeśli jest to wymagane przez prawo lub w kontekście postępowania sądowego.',
    title_4: '4. Bezpieczeństwo danych',
    text_4: 'Wdrażamy odpowiednie środki techniczne i organizacyjne w celu ochrony Twoich danych przed nieautoryzowanym dostępem, utratą lub ujawnieniem. Jednak żadny system bezpieczeństwa nie jest całkowicie nieprzenikniony i nie możemy zagwarantować całkowitego bezpieczeństwa Twoich danych.',
    title_5: '5. Twoje prawa',
    text_5: 'Masz prawo do:<br/><br/>- Żądania dostępu do swoich danych osobowych.<br/>- Poprawienia lub zaktualizowania swoich informacji.<br/>- Żądania usunięcia swoich danych, jeśli jest to dozwolone przez prawo.<br/>- Rezygnacji z otrzymywania komunikatów marketingowych.',
    title_6: '6. Zmiany w Polityce Prywatności',
    text_6: 'Możemy okresowo aktualizować niniejszą Politykę Prywatności. Najnowsze zmiany zostaną opublikowane na tej stronie. Zachęcamy do regularnego przeglądania tej Polityki, aby być na bieżąco z wszelkimi zmianami.',
    title_7: '7. Dane kontaktowe',
    text_7: 'Jeśli masz jakiekolwiek pytania lub uwagi dotyczące niniejszej Polityki Prywatności, skontaktuj się z nami pod adresem:<br/><br/>**Smart Hotel Service**<br/>[Twój adres]<br/>[Twój telefon]<br/>[Twój e-mail]',
  },

  conditions: {
    header: 'WARUNKI UŻYTKOWANIA',
    title_1: '1. Postanowienia ogólne',
    text_1: '1.1 Witamy na stronie Smart Hotel Service ("My", "Nasza firma"). Niniejsze Warunki użytkowania ("Warunki") regulują korzystanie z naszej aplikacji mobilnej dla hoteli ("Aplikacja"). Korzystając z naszej Aplikacji, zgadzasz się przestrzegać tych Warunków. Jeśli nie zgadzasz się z tymi Warunkami, prosimy o niekorzystanie z naszej Aplikacji.<br/><br/>1.2 Zastrzegamy sobie prawo do zmiany tych Warunków w dowolnym momencie. Wszystkie zmiany wchodzą w życie z chwilą ich opublikowania w Aplikacji. Jesteś zobowiązany do okresowego sprawdzania tych Warunków pod kątem zmian.',
    title_2: '2. Korzystanie z Aplikacji',
    text_2: '2.1 Nasza Aplikacja jest przeznaczona do zarządzania hotelami i rezerwacji pokoi.<br/><br/>2.2 Jesteś zobowiązany korzystać z Aplikacji wyłącznie w celach legalnych i zgodnie ze wszystkimi obowiązującymi przepisami prawa.<br/><br/>2.3 Nie masz prawa korzystać z naszej Aplikacji do celów niezgodnych z prawem lub nieautoryzowanych, w tym, ale nie ograniczając się do, prób łamania naszej Aplikacji lub uzyskiwania nieautoryzowanego dostępu do niej.',
    title_3: '3. Rejestracja i konto',
    text_3: '3.1 Aby korzystać z niektórych funkcji naszej Aplikacji, może być konieczne założenie konta. Zgadzasz się podać dokładne i aktualne informacje podczas rejestracji.<br/><br/>3.2 Jesteś odpowiedzialny za utrzymanie poufności danych logowania do konta i za wszystkie działania wykonywane z Twojego konta.<br/><br/>3.3 Jesteś zobowiązany niezwłocznie poinformować nas o jakimkolwiek nieautoryzowanym użyciu Twojego konta lub innych naruszeniach bezpieczeństwa.',
    title_4: '4. Własność intelektualna',
    text_4: '4.1 Cała zawartość dostępna za pośrednictwem naszej Aplikacji, w tym teksty, grafika, logotypy, ikony, obrazy i oprogramowanie, jest własnością Smart Hotel Service lub jej licencjodawców i jest chroniona prawem autorskim oraz innymi przepisami o własności intelektualnej.<br/><br/>4.2 Nie masz prawa kopiować, zmieniać, rozpowszechniać, reprodukować ani sprzedawać jakiejkolwiek zawartości bez naszej uprzedniej pisemnej zgody.',
    title_5: '5. Prywatność',
    text_5: '5.1 Korzystanie z Aplikacji podlega naszej Polityce prywatności, która określa, jak zbieramy, wykorzystujemy i chronimy Twoje dane osobowe.<br/><br/>5.2 Korzystając z naszej Aplikacji, zgadzasz się na warunki naszej Polityki prywatności.',
    title_6: '6. Odpowiedzialność',
    text_6: '6.1 Nie ponosimy odpowiedzialności za jakiekolwiek bezpośrednie, pośrednie, przypadkowe, specjalne lub wynikowe szkody, które mogą wyniknąć z korzystania lub niemożności korzystania z naszej Aplikacji.<br/><br/>6.2 Nie gwarantujemy ciągłości ani bezbłędnego działania Aplikacji i nie ponosimy odpowiedzialności za jakiekolwiek błędy lub niedokładności.',
    title_7: '7. Zmiany i zakończenie',
    text_7: '7.1 Zastrzegamy sobie prawo do zmiany lub zaprzestania działania Aplikacji w dowolnym momencie, powiadamiając o tym z wyprzedzeniem.<br/><br/>7.2 Jesteś zobowiązany do zaprzestania korzystania z Aplikacji, jeśli nie zgadzasz się na jakiekolwiek zmiany w tych Warunkach.',
    title_8: '8. Prawo właściwe',
    text_8: '8.1 Niniejsze Warunki podlegają prawu Ukrainy. Wszelkie spory wynikające z tych Warunków będą rozstrzygane przez sądy Ukrainy.',
    title_9: '9. Informacje kontaktowe',
    text_9: '9.1 Jeśli masz pytania lub uwagi dotyczące tych Warunków, prosimy o kontakt pod adresem:<br/><br/>**Smart Hotel Service**<br/>[Twój adres]<br/>[Twój telefon]<br/>[Twój e-mail]',
  },

  drop_menu: {
    header: 'Sekcje',
    about_us: 'O nas',
    contact: 'Kontakt',
    client: 'Dla klienta',
    hotel: 'Dla hotelu',
    prices: 'Cena',
    privacy: 'POLITYKA PRYWATNOŚCI',
    condition: 'REGULAMIN'
  }
};
